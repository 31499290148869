import React from 'react'
import { Avatar, Box, Card , CardContent, CardMedia, Tab, Tabs} from '@mui/material'

import { Typography } from '@mui/material'
import { List, ListItem } from '@mui/material'
import { useEffect } from 'react'
import ShopAd from '../finance/adds/shopadd'
import BeansAd from '../finance/adds/beansadd'
import { Store } from '@mui/icons-material'
import StoreAdd from '../finance/adds/storeadd'

const pour = 'https://storage.googleapis.com/app_darkendimg/assets/pour-over.png'
const pourover = 'https://storage.googleapis.com/app_darkendimg/assets/pourover'
const mocha = 'https://storage.googleapis.com/app_darkendimg/assets/mocah-pot.png'
const mochapot = 'https://storage.googleapis.com/app_darkendimg/assets/mochapot'
const pottop = 'https://storage.googleapis.com/app_darkendimg/assets/pottop.png'
const koffiepot = 'https://storage.googleapis.com/app_darkendimg/assets/koffiepot.png'
const press = 'https://storage.googleapis.com/app_darkendimg/assets/french-top.png'
const pressy = 'https://storage.googleapis.com/app_darkendimg/assets/french-pressman.png'
const espressotop = 'https://storage.googleapis.com/app_darkendimg/assets/espressotop.png'
const espresso = 'https://storage.googleapis.com/app_darkendimg/assets/espresso1.png'
const jebena = 'https://storage.googleapis.com/app_darkendimg/assets/jebena.png'
const chemextop = 'https://storage.googleapis.com/app_darkendimg/assets/chemextop.png'
const chemex = 'https://storage.googleapis.com/app_darkendimg/assets/chemex.png'
// Define the brew methods and their details
const brewMethods = {
  "Pour Over ": {
    "image": pourover,
    
    'displayimage': pour,
    "instructions": [
      "Place a coffee filter in the basket.",
      "Add one to two tablespoons of coffee grounds per six ounces of water.",
      "Fill the water chamber with fresh, filtered water.",
      "Turn on the machine and let it brew.",
      "Stir the coffee in the carafe before serving."
    ]
  },
  "Drip ": {
    "image": pottop,
    
    'displayimage': koffiepot,
    "instructions": [
      "Place a coffee filter in the basket.",
      "Add one to two tablespoons of coffee grounds per six ounces of water.",
      "Fill the water chamber with fresh, filtered water.",
      "Turn on the machine and let it brew.",
      "Stir the coffee in the carafe before serving."
    ]
  },
  "Espresso": {
    "image": espressotop,
    'displayimage': espresso, 
    "instructions": [
      "Ensure the machine is preheated.",
      "Grind coffee beans to a fine consistency.",
      "Tamp down the coffee grounds in the portafilter.",
      "Lock the portafilter into the machine.",
      "Start the shot extraction.",
      "Serve immediately."
    ]
  },
  "Press": {
    "image": press,
    'displayimage': pressy,
    "instructions": [
      "Add coffee grounds to the French Press.",
      "Pour hot water into the chamber.",
      "Stir the coffee and water mixture.",
      "Attach the filter and cap.",
      "Press down the plunger.",
      "Steep for four minutes.",
      "Serve and enjoy."
    ]
  },
  "Chemex": {
    "image": chemextop,
    'displayimage': chemex,
    "instructions": [
      "Place a Chemex filter in the top of the brewer.",
      "Add coffee grounds to the filter.",
      "Pour a small amount of hot water to bloom the coffee.",
      "Pour remaining water in a circular motion.",
      "Wait for the coffee to drip through.",
      "Serve immediately."
    ]
  },
  "Mocha": {
    "image": mochapot,
    'displayimage': mocha,
    "instructions": [
      "Fill the bottom chamber with water.",
      "Add coffee grounds to the filter basket.",
      "Assemble the Mocha pot.",
      "Place on medium heat.",
      "Wait for the coffee to fill the upper chamber.",
      "Stir and serve."
    ]
  },
  "Jebena": {
    "image": jebena,
    'displayimage': jebena,
    "instructions": [
      "Boil water in the Jebena.",
      "Add coffee grounds.",
      "Allow the coffee to steep.",
      "Pour into another container to separate grounds.",
      "Pour back into Jebena.",
      "Serve in small cups."
    ]
  }
};

const DisplayBrewInstructions = ({ method }) => {
  const instructions = brewMethods[method]?.instructions || [];

  return (
    <>
      <Typography variant="h6">Brewing Instructions for {method}</Typography>
      <List>
        {instructions.map((instruction, index) => (
          <ListItem key={index}>
            <Typography variant="body1">{`${index + 1}. ${instruction}`}</Typography>
          </ListItem>
        ))}
      </List>
    </>
  );
};
function Brew() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    // Set the initial selectedMethod to the first method
    const initialMethod = Object.keys(brewMethods)[0];
    setSelectedMethod(initialMethod);
  }, []); // Empty dependency array ensures this effect runs once on mount

        const [selectedMethod, setSelectedMethod] = React.useState(null);

return (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>


<div
  style={{
    display: 'flex',
    overflowX: 'auto', // Horizontal scrolling
    width: '100%',
    gap: '16px', // Spacing between cards
    padding: '16px', // Optional padding for content
    scrollbarWidth: 'none', // For Firefox
    msOverflowStyle: 'none', // For Internet Explorer and Edge
  }}
>
  {Object.keys(brewMethods).map((method) => (
    <div
      key={method}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedMethod(method);
      }}
      className="brew-method"
      style={{
        flexShrink: 0, // Prevent shrinking for horizontal scroll
        width: '200px', // Fixed card width
      }}
    >
      <Card
        onClick={() => setSelectedMethod(method)}
        sx={{
          cursor: 'pointer',
          textAlign: 'center',
          borderRadius: 2,
          position: 'relative', // Required for overlay
          overflow: 'hidden', // Ensure content stays within card boundaries
          boxShadow: 3,
        }}
      >
        {/* Image as Card Cover */}
        <CardMedia
          component="img"
          height="140" // Adjust height as needed
          image={brewMethods[method].image}
          alt={method}
          sx={{
            width: '100%',
            objectFit: 'cover',
          }}
        />

        {/* Text Overlay */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
            color: '#fff', // White text for contrast
            padding: '8px',
            textAlign: 'center',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {method}
          </Typography>
        </Box>
      </Card>
    </div>
  ))}
</div>


      {/* Display selected brew method details */}
      {selectedMethod && (
        <Card sx={{ width: '100%'}}>
         <CardMedia component="img" height="300"  image={brewMethods[selectedMethod].displayimage} alt={selectedMethod} />
          
          <DisplayBrewInstructions method={selectedMethod} />

        </Card>
      )}
    <BeansAd />
    <StoreAdd/>
  </Box>
    
  )
}

export default Brew