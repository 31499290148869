import React, { useState, useEffect, useContext, Suspense } from 'react';
//import './App.css';
import { UserProvider }  from './context/usercontext';
import { BrowserRouter as Router, Routes, Route, Link, BrowserRouter, } from 'react-router-dom';
import LoginForm from './pages/login';
import KupsterDash from './pages/kupsterdash';
import FarmDash from './pages/farmdash';
import MobileFarm from './pages/Mobilefarm';
//import { client, } from "./constants/constants";
//import SignUp from './pages/signup';
import { ApolloProvider } from '@apollo/client';
import Web3ModalProvider from "./constants/Web3ModalProvider";
import Logic from './components/tests/logic';
import Update from './components/profile/update';
import Toe from './components/nav/bottom';
import CssBaseline from '@mui/material/CssBaseline';
import KTabs from './components/nav/tabular';
import Dhome from './pages/homedesk';
import Dsign from './pages/desksign';
import Header from './components/nav/mobilefarmer';
import ProdMan from './pages/prodman';
import Onboarding from './pages/onboarding';
import KronDet from './pages/storytales';
import PrivateRoute from './context/privateroute';
import WKoffie from './pages/koffiedetails';
import AllKoffie from './pages/worldkoffie';
import Tree from './pages/tree';
import CoffeePage from './components/tests/des';
import FarmerProfile from './components/tests/farmv';
import Thething from './components/mods/thething';
import Flavloader from './components/mods/flavloader';
import Feed from './pages/feed';
import ThreeScene from './components/mods/particles';
import Contracts from './components/tests/contracts';
import GameComponent from './components/mods/GameComponent';
import Barista from './components/tests/barista';
import KoffieSearch from './components/tests/ksearch';
import CoffeeMenu from './components/tests/coffeemenu';
import Lamda from './components/tests/serverless';
import Anancy from './components/tests/anancy';
import Legbo from './components/tests/assistbot';
import Aigql from './components/tests/aigqltalk';
import MainComponent from './components/finance/testshop';
import Bag from './components/finance/Bag';
import { BagProvider } from './context/BagContext';
import Aifood from './components/tests/aifood';
import UserDet from './pages/userdeets';
import Land from './pages/land';
import KoffieAI from './components/profile/workingai';
import Dark from './pages/dark';
import ContractDetails from './components/profile/contractdetails';
//const { startWebSocketServer } = require('./constants/websocketServer');
import { UserContext } from './context/usercontext';
//import PaymentSuccess from './components/finance/paid';
import PaymentSuccess from './components/tests/paytest';
import DoIT from './components/profile/doit';
import KupOn from './components/profile/onboarding/kupon';
import FarmOn from './components/profile/onboarding/farmon';
import RoastOn from './components/profile/onboarding/roaston';
import ShopOn from './components/profile/onboarding/shopon';
import BaristaOn from './components/profile/onboarding/baristaon';
import Explorer from './pages/explorer';
import Store from './components/mods/frontgrid';
import TheDark from './pages/thedark';
import InstallButton from './pages/appinstall';
import BlockchainLogin from './pages/meta';
import Koffieman from './components/finance/delivery';
import EntryPoint from './pages/entry';
import DailyPost from './components/posts/dailypost';
import DFiveDetails from './components/tests/fivebuy';
import DongFeng5 from './components/tests/dongfeng5';
import CoffeeValueChain from './components/mods/valuechain/chain';
import VChart from './components/mods/valuechain/chainchart';
import Baristadash from './components/profile/dashboards/baristadash';
import { client } from './constants/constants';
import PostDetailsPage from './components/posts/singlepost';
import  {CustomThemeProvider} from './components/profile/settings/settings';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { __DEV__ } from '@apollo/client/utilities/globals';
import { createContext} from 'react';
import GoalComponent from './components/profile/GoalComponent';
import MainSt from './pages/mainstreet';
import CoffeeShopSearch from './components/finance/reviews/searchshop';
import OllieAI from './components/profile/oliver';
import AppGuide from './components/mods/step';
import Us from './pages/us';
import Top from './components/nav/top';
import DeskCoin from './pages/desk/deskcoin';
import Logics from './pages/desk/dash';
import Chron from './pages/chron';
import UserMarket from './components/finance/usermarket';
import FromAdds from './components/finance/prods/fromadds';
import MarketComponent from './components/finance/usershop';
import FromBeans from './components/finance/prods/frombeans';
import BeansComponent from './components/finance/beanshop';
import { BeanBagProvider } from './context/beanBagContext';
import BeanBag from './components/finance/BeanBag';
import BeanBaggy from './components/finance/BeanBag';
import Feedback from './components/posts/feedback';
//import GoogleSignIn from './components/mods/googauth';
import MarketWatch from './components/finance/marketwatch';
import Marketplace from './components/finance/nftmarket';
import { WelcomeProvider } from './context/welcomecontext';
//import WelcomeHome from './components/profile/onboarding/welcome';
import DigiLabel from './components/finance/labels/digilabel';
import Mission7 from './components/mods/mi7';
import Middle from './pages/middle';
import BeanWatch from './components/mods/bean-watch';
import BeanReport from './components/finance/prods/beanreport';
import Sub from './components/finance/prods/sub';
import PullToRefresh from 'react-simple-pull-to-refresh';
import KoffieWorld from './pages/allkoffies';
import Sigma from './components/profile/onboarding/sigma';
import Services from './components/finance/prods/services';
import GoogleOneTapLogin from './components/nav/onetap';
import Faq from './pages/faq';
import FaqPage from './pages/faqpage';
import BeanList from './components/finance/beanlist';
import ScrollToTop from './ScrollToTop';
import PrivacyPolicy from './pages/privacy';
import TermsOfService from './pages/terms';
import Login from './components/nav/mongologin';
import Home from './pages/home';
import CustomAppBar from './pages/enter';
import AboutSection from './pages/entrypoint/aboutus';
import SalesTracker from './pages/GoalTracker';
//import ShopApi from './components/finance/beanapi/shopapi';
import CoffeeShopDetails from './components/finance/beanapi/shoplist';
import ShopRedeemList from './components/finance/beanapi/shopreport';
import MobileLayout from './components/nav/tablayout';
import WhoAmI from './components/tests/whoami';
import KoffieCalc from './components/finance/koffiecalc';
import Skeletor from './components/mods/displays/skeleton';
import DeskUserDet from './pages/deskdeets';
import GoogleSignIn from './components/mods/googauth';
import CoffeeshopOn from './components/profile/onboarding/CoffeeshopOn';
import ShopTabs from './components/nav/shoptabs';
import Mobilemessage from './pages/messages';
import Deskmessage from './pages/sendmessage';
import ProdManMobile from './pages/prodmanmobile';


if (__DEV__) {  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}


// Create the context
export const ErrorContext = createContext({
  error: null,
  setError: () => {},
  clearError: () => {}
});

// Provider component
export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  // Function to clear the error
  const clearError = () => setError(null);

  return (
    <ErrorContext.Provider value={{ error, setError, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
};

function App() {
  const [user, setUser] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);  // Theme state
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isInstalled, setIsInstalled] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  
  // Handling window resize
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  
  // Handling window resize
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
  
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  
  const isMobile = windowWidth <= 768;
  
  

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  

  return (
  
    <ErrorProvider>
    <BrowserRouter>
    <ScrollToTop />
    <ApolloProvider client={client}>
      {/* defult to dark mode */}
<CustomThemeProvider>
    <React.Fragment>
      <CssBaseline />
      <WelcomeProvider>
    
   
    <Web3ModalProvider>
      <UserProvider>
            
            <BagProvider>
              <BeanBagProvider>
               
            
            {user}
            <Routes>
              <Route path='/terms-of-service' element={<TermsOfService/>} />
              <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
              <Route path='/' element={<Login/>} />
              <Route path='/contractdetails' element={<ContractDetails/>} />
              <Route path="/blockchain-login" element={<BlockchainLogin/>} />
              <Route path='/faq' element={<Faq/>} />
              <Route path='/tracker' element={<SalesTracker/>} />
              <Route path="/payment-test/" element={<Mission7/> } />
              <Route path='/aboutus' element={<AboutSection/>} />
              <Route element={<PrivateRoute/>}>
              <Route path="/serv" element={<Services />} />
              <Route path="/shoptabs" element={<ShopTabs/>} />
              <Route path="/home" element={<KTabs/>} />
              <Route path="/subscribe" element={<Sub />} />
              <Route path="/customs" element={<ShopOn />} />
              <Route path="/posts/:_id" element={<PostDetailsPage />} />
              <Route path='/header' element={isMobile?  <Header/>: null} />
              <Route path='/delivery' element={<Koffieman/>}/>
              <Route path='/sigma' element={<Sigma/>} />
              <Route path='/land' element={<Land/> } /> 
              <Route path="/messages" element={ <Deskmessage />} />

              <Route path='/calculator' element={<KoffieCalc/>} />
              <Route path='/skeletor' element={<Skeletor/>} />
              <Route path='/logic' element={<Logic />} />
              <Route path='/kupon' element={<KupOn/>} />
              <Route path='/bean-bag' element={<BeanBaggy/>} />
              <Route path='/market-watch' element={<MarketWatch/>} />
              <Route path="/payment-success/" element={<PaymentSuccess/> } />
              <Route path='/middle' element={<Middle/>} />
              <Route path='/kupster-dashboard' element={<KupsterDash/>} />
              <Route path='/roaster-dashboard' element={<KupsterDash/>} />
              <Route path='/coffeeShop-dashboard' element={<KupsterDash/>} />
              <Route path='/barista-dashboard' element={<Baristadash/>} />
              <Route path= '/kupster-on' element={<KupOn/>} />
              <Route path='/farmon' element={<FarmOn/>} />
              <Route path='/roaster-on' element={<RoastOn/>} />
              <Route path='/shop-on' element={<ShopOn/>} />
              <Route path='/appguide' element={<AppGuide/>} />
              <Route path='/barista-on' element={<BaristaOn/>} />
              <Route path='/beanlist' element={<BeanList/>} />
              <Route path='/vchart' element={<VChart/>} />
              <Route path='/bounce' element={<Flavloader/>} />
              <Route path='/farmer-dashboard' element={isMobile ? <MobileFarm/> : <FarmDash/>} />
              <Route path='/bag' element={<Bag/>} />
              <Route path="/goal/:_id" element={<DoIT/>} />
              <Route path="/web3market" element={<Marketplace/>} />
              <Route path="/chron" element={<Chron/>} />
              <Route path="/koffiestate/:countryName" element={<KoffieWorld/>} />
              <Route path='/kcoin' element={<DeskCoin/>} />
              <Route path='/shop' element={<MainComponent/>} />
              <Route path='/wheel' element={<KoffieAI/>} />
              <Route path='/user-market' element={<UserMarket/>} />
              <Route path='/frombeans/:_id' element={<BeansComponent/>} />
              <Route path='/ollie' element={<OllieAI/>} />
              <Route path='/mobile' element={<MobileLayout/>} />
              <Route path='/post' element={<DailyPost/>} />
              <Route path='/thething' element={<Thething />} />
              
              <Route path="/darkroad/:_id" element={<Dark />} />
              <Route path="/dhome/" element={<Dhome />} />
              <Route path="/goals" element={<GoalComponent />} />
              <Route path='/coffeeshopon' element={<CoffeeshopOn/>} />
              <Route path="/roaston" element={<RoastOn />} />
              <Route path="/baristaon" element={<BaristaOn />} />
              <Route path="/reviews" element={<CoffeeShopSearch />} />
              <Route path="/claimbeans/:_id" element={<CoffeeShopDetails />} />
              <Route path="/thedark" element={<TheDark/>}/>
              <Route path="/dfive" element={<DongFeng5/>} />
              <Route path="/dfivestore/:_id" element={<DFiveDetails/>} />
              <Route path='/tree' element={<Tree/>} />
              <Route path="/maindash" element={<Logics/>} />
              <Route path="/main" element={<MainSt />} />
              <Route path="/wave" element={<ThreeScene />} />
              <Route path="/bean-report" element={<BeanReport />} />
              <Route path="/beanwatch" element={<BeanWatch />} />
              <Route path="/contracts" element={<Contracts />} />
              <Route path="/allkoffie" element={<AllKoffie />} />
              <Route path="/thekoffie/:_id" element={<WKoffie />} />
              <Route path="/koffie/:countryName" element={<WKoffie />} />
              <Route path="/dashboard" element={<WhoAmI />} />
              <Route path='/update' element={<Update/>} />
              <Route path='/chat' element={<Logic />} />
              <Route path='/profile' element={<Update/>} />
              <Route path='/product/:_id' element={<MainComponent/>} />
              <Route path='/des/' element={<CoffeePage/>} />
              <Route path='/barista' element={<Barista/>} />
              <Route path='/ksearch' element={<KoffieSearch/>} />
              <Route path='/kmenu' element={<CoffeeMenu/>} />
              <Route path='/lamda' element={<Lamda/>} />
              <Route path="/user/:userID" element={<UserDet />} />
              {!isMobile ?  <Route path='/kupster/:_id' element={<DeskUserDet />} /> : <Route path='/kupster/:_id' element={<UserDet />} />}
              
              {!isMobile ?  <Route path='/roaster/:_id' element={<DeskUserDet />} /> : <Route path='/roaster/:_id' element={<UserDet />} />}
              {!isMobile ?  <Route path='/coffeeshop/:_id' element={<DeskUserDet />} /> : <Route path='/coffeeshop/:_id' element={<UserDet />} />}
              {!isMobile ?  <Route path='/barista/:_id' element={<DeskUserDet />} /> : <Route path='/barista/:_id' element={<UserDet />} />}
              {!isMobile ?  <Route path='/Farmer/:_id' element={<ProdMan />} /> : <Route path='/Farmer/:_id' element={<ProdManMobile />} />}
              <Route path='/farmer/:_id' element={<FarmerProfile/>} />
              <Route path='/roaster/:_id' element={<UserDet/>} />
              <Route path='/user/:_id' element={<UserDet/>} />
              <Route path="/beantown/farmer/:_id" element={<DeskUserDet/>} />
              <Route path='/coffeeshop/:_id' element={<UserDet/>} />
              <Route path="/shop-redeems" element={<ShopRedeemList />} />

              <Route path="/story/:_id" element={<KronDet />} />
              
              <Route path="/userprof/:_id" element={<UserDet/>} />
              <Route path="/barista/:_id" element={<UserDet/>} />
              <Route path="/chain" element={<CoffeeValueChain/>} />
              <Route path="/store" element={<Store/>} />
              <Route path="/blockuser" element={<Logic />} />
              <Route path="/coffeeai" element={<Anancy />} />
              <Route path="/aiassist" element={<Legbo />} />
              <Route path="/berrycrush" element={<GameComponent />} />
              <Route path="/feed" element={<Feed />} />
              <Route path="/fromadds/:_id" element={<MarketComponent />} />
              <Route path="/darkroad" element={<Aigql />} />
              <Route path="/aifood" element={<Aifood />} />
              <Route path="/buyit" element={<Land />} />
              <Route path="/explore" element={<Explorer />} />
              <Route path="/us" element={<Us />} />
              <Route path="/feedback" element={<Feedback />} />
             
              {/*... other routes */}
              </Route>
          
            </Routes>
            
            
            </BeanBagProvider>
            </BagProvider>
           
       
         
        </UserProvider>
       
    </Web3ModalProvider> 
    
 
    </WelcomeProvider>

  </React.Fragment>
  </CustomThemeProvider>
  </ApolloProvider>
  </BrowserRouter>
  </ErrorProvider>


  );
}

export default App;
