import React, { useContext, useEffect, useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client';
import { Button, Drawer, TextField, Box, Typography, CardMedia, Card, Chip, Snackbar, Divider, Fab, CardActions, CardContent, useMediaQuery, Grid } from '@mui/material';
import { UserContext } from '../../../context/usercontext';
import Progress from '../../../components/mods/progress';
import { useItem } from '../../finance/mangeshop/useitems';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ArrowBack } from '@mui/icons-material'
//import AddItem from '../components/finance/mangeshop/additem'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import useFileUpload from '../cloud';
import MultiFileUpload from '../productui';
import BeanReport from '../../finance/prods/beanreport';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import AiGen2 from '../../finance/adds/aifeed2';
import { Link } from 'react-router-dom';



const FARMER_BY_USERID = gql`
  query KupsterByUserID($userID: ObjectId!) {
    farmprof(query: { userID: $userID }) {
      Title
      _id
      Hectare
      Location
      userID
      ImageUrl
      Elevation
      Income
      Production
      Trees
      Process
      countryName
      Outlet
      Employees
      imagelist
      Cherries
    }
  }
`;



const INSERT_FARMPROF = gql`
  mutation InsertFarmprof($data: FarmprofInsertInput!) {
    insertOneFarmprof(data: $data) {
      _id
      Title
      Hectare
      Location
      userID
      ImageUrl
      Elevation
      Income
      Production
      Trees
      Process
      countryName
      Outlet
      Employees
      imagelist
      Cherries
    }
  }
`;

const UPDATE_FARMPROF = gql`
  mutation UpdateFarmprof($query: FarmprofQueryInput, $set: FarmprofUpdateInput!) {
    updateOneFarmprof(query: $query, set: $set) {
      _id
      Title
      Hectare
      Location
      userID
      ImageUrl
      Elevation
      Income
      Production
      Trees
      Process
      countryName
      Outlet
      Employees
      imagelist
      Cherries
    }
  }
`;

const CREATE_KUPSTER = gql`
  mutation CreateKupster($data: KupsterInsertInput!) {
    insertOneKupster(data: $data) {
      _id
      favorite_koffie
      Title
      userID
    }
  }
`;

const UPDATE_ONE_KUPSTER = gql`
  mutation UpdateUserprof($query: KupsterQueryInput!, $set: KupsterUpdateInput!) {
    updateOneKupster(query: $query, set: $set) {
      _id
      favorite_koffie
      Title
      userID
      
    }
  }
`;

const GET_BEAN_COUNT = gql`
  query GetBeanCount($_id: ObjectId!) {
    userprof(query: {_id: $_id}) {
      beanCount
      _id
    }
  }
`;

const DECREMENT_BEAN_COUNT = gql`
  mutation DecrementBeanCount($_id: ObjectId!, $beanCount_inc: Int!) {
    updateOneUserprof(query: { _id: $_id }, set: { beanCount_inc: $beanCount_inc }) {
      _id
      beanCount
    }
  }
`;


function FarmerDash() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const {user} = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpen1, setIsDrawerOpen1] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

 const { Nickname } = user.customData;
  console.log("User from context:", Nickname);

  console.log("Querying with userID:", user.id);
  console.log("Querying with userID:", user.id);
  const { loading, error, data, refetch } = useQuery(FARMER_BY_USERID, {
    variables: { userID: user ? user.id : null },
  });

   // Ensure that the user ID is available
  useEffect(() => {
    console.log("User from context:", user);
  }, [user]);

  const { file, previewURL, handleFileChange, uploadFile } = useFileUpload(); // Use your custom hook
    const [showDialog, setShowDialog] = useState(false);
    const [beanCount, setBeanCount] = useState(0); // Initialize with 0 beans
    const [postSubmitted, setPostSubmitted] = useState(false); // Added state for postSubmitted
    const fileInputRef = React.createRef();
    const { data: beanData, loading: beanLoading, error: beanError } = useQuery(GET_BEAN_COUNT, {
      variables: { _id: user.id },
    });
    const [decrementBeanCount] = useMutation(DECREMENT_BEAN_COUNT);
    const [updateKupster] = useMutation(UPDATE_ONE_KUPSTER);
  // Add this line to handle errors
    

    useEffect(() => {
        if (beanData && beanData.userprof) {
            setBeanCount(beanData.userprof.beanCount);
        }
    }, [beanData]);

  const [editProduct, setEditProduct] = useState(null);  // For storing product being edited
  const [openEditDialog, setOpenEditDialog] = useState(false);  // For controlling edit dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);  // For controlling delete dialog
  const { products, handleInsertProduct, handleUpdateProduct, handleDeleteProduct } = useItem();
 
  const handleDrawerOpen1 = () => {
    setIsDrawerOpen(true);
  };
  
  const handleDrawerClose1 = () => {
    setIsDrawerOpen(false);
  };
  const query = { userID: user ? user.id : null }; // assuming you identify users by an _id

  const dataExists = data && data.kupster && data.kupster.userID; // Example condition
 
  // Drawer with Form
 
  

  const startEditing = (product) => {
    setEditProduct(product);
    setOpenEditDialog(true);
  };

  const startDeleting = (product) => {
    setEditProduct(product);
    setOpenDeleteDialog(true);
  };

  const handleEdit = () => {
    // Call your update function here, e.g.
    handleUpdateProduct(editProduct);
    setOpenEditDialog(false);
  };

  const handleDelete = () => {
    // Call your delete function here, e.g.
    handleDeleteProduct(editProduct._id);
    setOpenDeleteDialog(false);
  };


 
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  

 // Function to determine cols and rows based on some condition, you can adjust this logic
 const getColsAndRows = (product) => {
  // You could replace this with your actual logic to determine featured products
  return product.featured ? { cols: 2, rows: 2 } : { cols: 1, rows: 1 };
};

const handleOpenDialog = () => {
  setOpenDialog(true);
};

const handleCloseDialog = () => {
  setOpenDialog(false);
  setOpenSnackbar(true);
};

const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpenSnackbar(false);
};

 const formik = useFormik({
  initialValues: {
    productName: '',
    productDescription: '',
    productPrice: '',
    // productImage: '', // This can be removed if you're not using it
  },
  onSubmit: async (values, { resetForm }) => {
    // Directly call uploadFile function to upload the image
    
    const uploadedImageURL = await uploadFile(file, user.id);

    // Check if the upload was successful
    if (!uploadedImageURL) {
      console.error('File upload failed: no image URL returned');
      handleCloseDialog();
      return;
    }
    // Create the new product item with the uploaded image URL
    const newProduct = {
      name: values.productName,
      description1: values.productDescription,
      price: values.productPrice,
      imageurl: uploadedImageURL, // Use the uploaded image URL here
      userID: user.id, // Make sure this is the correct way to access the user's ID
    };
      
    // Insert the product item
    handleInsertProduct(newProduct);

    // Reset the form and close any modals/drawers
    resetForm(); // You can use the formik bag's resetForm function
    handleCloseDialog();
  },
  // ... You can add validationSchema or validate function if needed
});


  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  
  useEffect(() => {
    if (user && user.user && user.user.id) {
      // Trigger refetch or some other action
    }
  }, [user]);
  
  
    
  console.log("Data from query:", data);
  const handleFabClick = () => {
    fileInputRef.current.click();
};





const [formData, setFormData] = useState({
  Title: '',
  Hectare: '',
  Location: '',
  ImageUrl: '',
  Elevation: '',
  Income: '',
  Production: '',
  Trees: '',
  Process: '',
  countryName: '',
  Outlet: '',
  Employees: '',
  imagelist: [],
  Cherries: '',
  userID: user.id,
});
const [selectedFarm, setSelectedFarm] = useState(null); // Track the farm profile being edited
const [farmProfiles, setFarmProfiles] = useState(data?.farmprof || []); // Store data in local state

const [insertFarmprof] = useMutation(INSERT_FARMPROF);
const [updateFarmprof] = useMutation(UPDATE_FARMPROF);

// Open the drawer and set form data for edit or add
const handleDrawerOpen2 = (farmprof = null) => {
  if (farmprof) {
    setSelectedFarm(farmprof);
    setFormData({
      Title: farmprof.Title || '',
      Hectare: farmprof.Hectare || '',
      Location: farmprof.Location || '',
      ImageUrl: farmprof.ImageUrl || '',
      Elevation: farmprof.Elevation || '',
      Income: farmprof.Income || '',
      Production: farmprof.Production || '',
      Trees: farmprof.Trees || '',
      Process: farmprof.Process || '',
      countryName: farmprof.countryName || '',
      Outlet: farmprof.Outlet || '',
      Employees: farmprof.Employees || '',
      imagelist: farmprof.imagelist || [],
      Cherries: farmprof.Cherries || ''
    });
  } else {
    setSelectedFarm(null);
    setFormData({
      Title: '',
      Hectare: '',
      Location: '',
      ImageUrl: '',
      Elevation: '',
      Income: '',
      Production: '',
      Trees: '',
      Process: '',
      countryName: '',
      Outlet: '',
      Employees: '',
      imagelist: [],
      Cherries: ''
    });
  }
  setIsDrawerOpen1(true);
};

const handleDrawerClose2 = () => {
  setIsDrawerOpen1(false);
  setSelectedFarm(null);
};

// Handle form field changes
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({ ...prevData, [name]: value }));
};

// Handle form submission for adding or updating
const handleSubmit1 = async (e) => {
  e.preventDefault();
  try {
    const farmData = {
      ...formData,
      userID: user.id, // Include userID for insertion
    };

    if (selectedFarm) {
      // Update existing farm profile
      await updateFarmprof({
        variables: {
          set: farmData,
          query: { _id: selectedFarm._id },
        },
      });
    } else {
      // Insert new farm profile with userID
      await insertFarmprof({
        variables: { data: farmData },
      });
    }
    // Close the drawer, refetch data, and reset form
    handleDrawerClose2();
    refetch();
  } catch (error) {
    console.error("Error saving Farm Profile:", error);
  }
};


  // Add this line to handle errors
  if (error) return <div>Error: {error.message}</div>;

  if (loading) return <div><Progress/></div>;
  
  return (
    <div>
   
   <div>
  <div>
    {data && data.farmprof
      ? Array.isArray(data.farmprof)
        ? data.farmprof.map((item, index) => (
            <div key={index}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {item.Title || "No Title"}
                {item.Hectare || "No Hectare"}
                {item.Location || "No Location"}
                {item.ImageUrl || "No Image"}
                {item.Elevation || "No Elevation"}
                {item.Income || "No Income"}
                {item.Production || "No Production"}
                {item.Trees || "No Trees"}
                {item.Process || "No Process"}
                {item.countryName || "No Country"}
                {item.Outlet || "No Outlet"}
                {item.Employees || "No Employees"}
                {item.imagelist.length > 0 ? item.imagelist.join(', ') : "No Images"}
                {item.Cherries || "No Cherries"}
              </div>
              <Button onClick={() => handleDrawerOpen2(item)}>Edit</Button> {/* Edit Button */}
            </div>
          ))
        : (
          <Grid container spacing={2}>
        
            <Grid item xs={6} sm={6} md={4} >
              
                  <Typography gutterBottom variant="h5" component="div">
                    {data.farmprof.Title || "No Title"}
                  </Typography>
                  </Grid>
                 
                  <Grid item xs={6} sm={6} md={4} >
                  <Typography variant="body2" color="text.secondary">
                    {data.farmprof.Location || "No Location"}
                  </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} >
                  <Typography variant="body2" color="text.secondary">
                   Acres  {data.farmprof.Hectare || "No Hectare"}
                  </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} >
                  <Typography variant="body2" color="text.secondary">
                   Elevation  {data.farmprof.Elevation || "No Elevation"}
                  </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} >
                  <Typography variant="body2" color="text.secondary">
                   Earnings {data.farmprof.Income || "No Income"}
                  </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} >
                  <Typography variant="body2" color="text.secondary">
                 Annual Production {data.farmprof.Production || "No Production"}
                  </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} >
                  <Typography variant="body2" color="text.secondary">
                    Trees {data.farmprof.Trees || "No Trees"}
                  </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} >
                  <Typography variant="body2" color="text.secondary">
                    Proccess {data.farmprof.Process || "No Process"}
                  </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} >
                  <Typography variant="body2" color="text.secondary">
                    Counrty {data.farmprof.countryName || "No Country"}
                  </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} >
                  <Typography variant="body2" color="text.secondary">
                   Market {data.farmprof.Outlet || "No Outlet"}
                  </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} >
                  <Typography variant="body2" color="text.secondary">
                   Employees {data.farmprof.Employees || "No Employees"}
                  </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} >
                  <Typography variant="body2" color="text.secondary">
                    {data.farmprof.imagelist.length > 0 ? data.farmprof.imagelist.join(', ') : "No Images"}
                  </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} >
                  <Typography variant="body2" color="text.secondary">
                   Cherries {data.farmprof.Cherries || "No Cherries"}
                  </Typography>
                  </Grid>
               
                <CardActions>
                  <Chip
                    label="Edit"
                    onClick={() => handleDrawerOpen2(data.farmprof)}
                    sx={{
                      ml: 'auto',
                      backgroundColor: '#077336', // Soft green background
                      color: '#ffffff', // Text color for contrast
                      textTransform: 'none', // Prevent uppercase transformation
                    }}
                  />
                </CardActions>
              
           
        
        </Grid>
          )
      : (
        <Card style={{ textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Options
          </Typography>
          <Typography variant="subtitle1">
            Set up your custom settings for a better experience
          </Typography>
          <Chip label="Add Farm Profile" style={{ margin: '10px 0' }} onClick={() => handleDrawerOpen2()} />
        </Card>
      )}
  </div>

  {/* Render Drawer for Adding/Editing */}
  <Drawer anchor="bottom" open={isDrawerOpen1} onClose={handleDrawerClose2}>
    <Box
      sx={{ width: '100%', padding: 2, mb: 5 }}
      role="presentation"
      component="form"
      noValidate
      onSubmit={handleSubmit1}
    >
     
     <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 1, marginBottom: 1 }}>
  <IconButton onClick={handleDrawerClose2}>
  <CloseIcon />
  </IconButton>
  <Typography variant="h6" component="h2" fontWeight={800}>
    {selectedFarm ? "Edit Profile" : "Add New Profile"}
  </Typography>
</Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 , minWidth: '100%', mb: 5}}>
      <TextField size='small' name="Title" label="Title" value={formData.Title} onChange={handleChange} fullWidth />
      <TextField size='small'  name="Hectare" label="Hectare" value={formData.Hectare} onChange={handleChange} fullWidth />
      <TextField size='small'  name="Location" label="Location" value={formData.Location} onChange={handleChange} fullWidth />
      <TextField size='small'  name="ImageUrl" label="Image URL" value={formData.ImageUrl} onChange={handleChange} fullWidth />
      <TextField size='small'  name="Elevation" label="Elevation" value={formData.Elevation} onChange={handleChange} fullWidth />
      <TextField size='small'  name="Income" label="Income" value={formData.Income} onChange={handleChange} fullWidth />
      <TextField  size='small' name="Production" label="Production" value={formData.Production} onChange={handleChange} fullWidth />
      <TextField size='small'  name="Trees" label="Trees" value={formData.Trees} onChange={handleChange} fullWidth />
      <TextField size='small'  name="Process" label="Process" value={formData.Process} onChange={handleChange} fullWidth />
      <TextField size='small'  name="countryName" label="Country Name" value={formData.countryName} onChange={handleChange} fullWidth />
      <TextField size='small'  name="Outlet" label="Outlet" value={formData.Outlet} onChange={handleChange} fullWidth />
      <TextField size='small'  name="Employees" label="Employees" value={formData.Employees} onChange={handleChange} fullWidth />
      <TextField size='small'  name="Cherries" label="Cherries" value={formData.Cherries} onChange={handleChange} fullWidth />
      <Button type="submit" variant="contained" color="primary">
        {selectedFarm ? "Update Farm Profile" : "Add Farm Profile"}
      </Button>
      </Box>
      
    </Box>
  </Drawer>
</div>

    
    <Box sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }}>
    <Card sx={{ 
  height: '10vh', 
  width: '100%',
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'space-between', 
  padding: '0 10px',
 
}}>
  <Typography variant="h6">
    Manage Shop
  </Typography>
  
  <Chip
    label="Manage Profile"
    onClick={handleDrawerOpen}
    backgroundColor="#2fa849"
    style={{ cursor: 'pointer', backgroundColor: '#2fa849', color: '#fff' }}
  />
</Card>

</Box>

 
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
      {!isMobile && (
     <Drawer anchor='right' open={drawerOpen} onClose={handleDrawerClose1}sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
      <Box sx={{ maxWidth: 400, padding: 3,  border: 'solid 1px #000', paddingBottom: 2}}
        role="presentation"
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
         <Box sx={{ width: '100%', padding: 0 }}>
        <Box display="flex" alignItems="center">
      <IconButton onClick={handleDrawerClose}>
        <ArrowBack />
      </IconButton>
      <Typography variant="h6" sx={{fontWeight: 600, margin: 0}} >
              {Nickname}'s Collection 
            
            </Typography>
    </Box>
          
    
          <Box sx={{ display: 'flex', padding: 1,justifyContent: 'center', alignItems: 'center' }}>
          
          <Card sx={{ padding: 2, marginBottom: 3, borderRadius: 5, boxShadow: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img 
          src='https://storage.googleapis.com/app_darkendimg/newton.png' 
          alt={user.name} 
          width={100} 
          style={{ opacity: 0.9, borderRadius: '50%' }} 
        />
       
            <Chip label="Update payment method" onClick={handleDrawerOpen2} /> 
      </Box>
      <CardContent sx={{ textAlign: 'center', borderRadius: 5, border: 'solid 0px #d1d1d1', marginTop: 0 }}>
        <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary' }}>
          In Koffieland, the economy thrives on artisanal craftsmanship. Each product stands out with its unique character and quality, embodying the irreplaceable touch of human hands. 
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt:2 }}> 
            <Fab size='medium'  onClick={handleOpenDialog} aria-label="add" sx={{  }} >
            <AddIcon />
            </Fab>  
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary', mt: 1 }}>
              Add Product
            </Typography>   
            
            </Box> 
      </CardContent>
      
    </Card>

<Drawer anchor="right" open={isDrawerOpen1} onClose={handleDrawerClose2}>
<Box 
  sx={{ 
    display: 'flex', 
    flexDirection: 'row', 
    width: '100%', 
    padding: 3, 
    alignItems: 'center',
    justifyContent: 'space-between' 
  }}
>
  <IconButton onClick={handleDrawerClose2}>
    <ArrowBack />
  </IconButton>
  <Typography variant="h6" sx={{ fontWeight: 600, ml: 'auto' }}>
    Payment Method
  </Typography>
</Box>

      <Box sx={{ width: '100%', padding: 3 }}>
        
        <BeanReport />
      </Box>
    </Drawer>

      
          <Dialog  open={openDialog} onClose={handleCloseDialog}>
          <div style={{ display: 'flex', alignItems: 'flex-start', padding: 1 }}>
  <IconButton onClick={handleCloseDialog} aria-label="Go back">
    <ArrowBack />
  </IconButton>
</div>

<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' , padding: 2 }}>
    <Typography variant='h5' sx={{ fontWeight: 600 }}>Add Product</Typography>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      X50 <img src='https://storage.googleapis.com/app_darkendimg/lilbeanie.png' alt='beans' width={30} /> beans
    </Box>
  </Box>
</Box>
<MultiFileUpload />
        
         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 2 }}>
          
          <Typography variant="h6" sx={{ fontWeight: 400, textAlign: 'center', margin: 2 }}>
                      Let our AI Smart Feed help you sell your products
          </Typography>
          <Divider variant='middle'  />
          </Box>
          <AiGen2 />
          </Dialog>
         
          <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message="Item added successfully"
      />
          </Box>
        </Box>
        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
         
        
        <Divider variant='middle'  />
      {/* Edit Product Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          {/* Place your form fields for editing here, e.g., */}
          <TextField
    label="Product Name"
    fullWidth
    value={editProduct?.name || ''}
    onChange={(e) => setEditProduct({ ...editProduct, name: e.target.value })}
    sx={{ mb: 2, mt: 2 }}  
  />
  <TextField
    label="Product Price"
    fullWidth
    value={editProduct?.price || ''}
    onChange={(e) => setEditProduct({ ...editProduct, price: e.target.value })}
    sx={{ mb: 2 }} 
  />
  <TextField
    label="Product Description"
    fullWidth
    value={editProduct?.description1 || ''}
    onChange={(e) => setEditProduct({ ...editProduct, description1: e.target.value })}
    sx={{ mb: 2 }}  
  />
        
          {/* Add more fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Cancel</Button>
          <Button onClick={handleEdit} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Product Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this product?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      <div>
  {products.length > 0 ? (
    <List>
      {products.map((product, index) => (
        <Card key={index} sx={{ padding: 0, marginBottom: 2 }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar variant="rounded">
                <img src={product.imageurl} alt={product.name} width={40}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={product.name}
              secondary={product.description1}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => startEditing(product)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => startDeleting(product)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Card>
      ))}
    </List>
  ) : (
    <Card sx={{ padding: 2, margin: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="text.secondary">
        You have no items to edit
      </Typography>
    </Card>
  )}
</div>
        </div>
        </Box>
      </Drawer>

    )}
    </div>
    
      
<div>
   {isMobile && (
          <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{ width: "100%" }}
      >
        <Box sx={{ width: '100%', padding: 0 }}>
        <Box display="flex" alignItems="center">
      <IconButton onClick={handleDrawerClose}>
        <ArrowBack />
      </IconButton>
      <Typography variant="h6" sx={{fontWeight: 600, margin: 0}} >
              {Nickname}'s Collection 
            
            </Typography>
    </Box>
          
    
          <Box sx={{ display: 'flex',  padding: 1,justifyContent: 'center', alignItems: 'center' }}>
          
          <Card sx={{ padding: 2, marginBottom: 3, borderRadius: 5, boxShadow: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img 
          src='https://storage.googleapis.com/app_darkendimg/newton.png' 
          alt={user.name} 
          width={100} 
          style={{ opacity: 0.9, borderRadius: '50%' }} 
        />
       
            <Chip label="Update payment method" onClick={handleDrawerOpen2} /> 
      </Box>
      <CardContent sx={{ textAlign: 'center', borderRadius: 5, border: 'solid 0px #d1d1d1', marginTop: 0 }}>
        <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary' }}>
          In Koffieland, the economy thrives on artisanal craftsmanship. Each product stands out with its unique character and quality, embodying the irreplaceable touch of human hands. 
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt:2 }}> 
            <Fab size='medium'  onClick={handleOpenDialog} aria-label="add" sx={{  }} >
            <AddIcon />
            </Fab>  
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary', mt: 1 }}>
              Add Product
            </Typography>   
            
            </Box> 
      </CardContent>
      
    </Card>
    <Drawer anchor="bottom" open={isDrawerOpen1} onClose={handleDrawerClose2}>
    <Box 
  sx={{ 
    display: 'flex', 
    flexDirection: 'row', 
    width: '100%', 
    padding: 3, 
    alignItems: 'center',
    justifyContent: 'space-between' 
  }}
>
  <IconButton onClick={handleDrawerClose2}>
    <ArrowBack />
  </IconButton>
  <Typography variant="h6" sx={{ fontWeight: 600, ml: 'auto' }}>
    Payment Method
  </Typography>
</Box>

      <Box sx={{ width: '100%', padding: 0, }}>
       
        <BeanReport />
      </Box>
    </Drawer>
          <Dialog fullScreen open={openDialog} onClose={handleCloseDialog} height= '100vh'>
          <div style={{ display: 'flex', alignItems: 'flex-start', padding: 1 }}>
  <IconButton onClick={handleCloseDialog} aria-label="Go back">
    <ArrowBack />
  </IconButton>
</div>

<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' , padding: 2 }}>
    <Typography variant='h5' sx={{ fontWeight: 600 }}>Add Product</Typography>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      X50 <img src='https://storage.googleapis.com/app_darkendimg/lilbeanie.png' alt='beans' width={30} /> beans
    </Box>
  </Box>
</Box>

         <MultiFileUpload />
         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 2 }}>
          
          <Typography variant="h6" sx={{ fontWeight: 400, textAlign: 'center', margin: 2 }}>
                      Let our AI Smart Feed help you sell your products
          </Typography>
          <Divider variant='middle'  />
          </Box>
          <AiGen2 />
          </Dialog>
         
          <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message="Item added successfully"
      />
          </Box>
        </Box>
        
        <div>
         
        
        <Divider variant='middle'  />
      {/* Edit Product Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent sx={{ padding: 2 ,}}>
          {/* Place your form fields for editing here, e.g., */}
          <TextField
    label="Product Name"
    fullWidth
    value={editProduct?.name || ''}
    onChange={(e) => setEditProduct({ ...editProduct, name: e.target.value })}
    sx={{ mb: 2, mt: 2 }}  
  />
  <TextField
    label="Product Price"
    fullWidth
    value={editProduct?.price || ''}
    onChange={(e) => setEditProduct({ ...editProduct, price: e.target.value })}
    sx={{ mb: 2 }} 
  />
  <TextField
    label="Product Description"
    fullWidth
    value={editProduct?.description1 || ''}
    onChange={(e) => setEditProduct({ ...editProduct, description1: e.target.value })}
    sx={{ mb: 2 }}  
  />
         
          {/* Add more fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Cancel</Button>
          <Button onClick={handleEdit} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Product Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this product?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      <div>
  {products.length > 0 ? (
    <List sx={{ marginBottom: 6 }}>
      {products.map((product, index) => (
        <Card key={index} sx={{ padding: 0, marginBottom: 0 }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar variant="rounded">
                <img src={product.imageurl} alt={product.name} width={40}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={product.name}
              secondary={product.description1}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => startEditing(product)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => startDeleting(product)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Card>
      ))}
    </List>
  ) : (
    <Card sx={{ padding: 2, margin: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="text.secondary">
        You have no items to edit
      </Typography>
    </Card>
  )}
</div>

      
    </div>
      </Drawer>
)}
</div>
<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1 }}>
   <Typography variant="caption" sx={{ fontWeight: 700, textAlign: 'center' }}>
      © 2021 Koffie Inc. All rights reserved. Powered by Java.
    </Typography>
  </Box>

    
  <Grid container spacing={3} sx={{ padding: 2 }}>
 
 {products.map((product) => (
   <Grid item key={product.id} xs={6} sm={6} md={3}>
     <Link to={`/product/${product.id}`} style={{ textDecoration: 'none' }}>
       <Card sx={{ borderRadius: 2, overflow: 'hidden', boxShadow: 3 }}>
         <CardMedia
           component="img"
           height="200"
           image={product.imageurl}
           alt={product.name}
           sx={{ objectFit: 'cover' }}
         />
         <CardContent sx={{ padding: 2 }}>
           <Typography variant="body1" sx={{ fontWeight: 600,  }}>
             {product.name}
           </Typography>
           <Typography variant="body1" color="text.secondary">
             ${ (parseFloat(product.price) / 100).toFixed(2) }
           </Typography>
         </CardContent>
       </Card>
     </Link>
   </Grid>
 ))}
</Grid>

  </div>
  )
}

export default FarmerDash;