import React, { useEffect, useState, useContext,  } from 'react';
import { useQuery, useMutation , gql} from '@apollo/client';
import { Box, Card, CardContent, Typography, List, ListItem, ListItemText, Dialog, Chip, ListItemSecondaryAction , useMediaQuery, DialogContent, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import RenewBeans from '../mods/dialogue/renewbeans';
import Progress from '../mods/progress';
import { UserContext } from '../../context/usercontext';
import {   GET_USER, UPDATE_USER_PROF } from '../../context/gqllogics';
import { makeStyles } from '@mui/styles';
import { DELETE_ONE_ITEM, } from '../finance/Bag';
import Heart from '../mods/displays/heart'; // Import
import { Avatar } from '@mui/material';
import { set } from 'lodash';
import { BagContext, useBag, } from '../../context/BagContext';
import confetti from 'canvas-confetti';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import kcoin from '../../images/kcoin.png';
import BeanCard from '../mods/beancard';
import { v4 as uuidv4 } from 'uuid';

export const GET_CART = gql`
  query GetCart($userID: ObjectId!) {
    carts(query: { userID: $userID, isPaid: false }) {
      userID
      beanies
      isPaid
      productId
      category
      beanies
      timeStamp
      redeemIds
      name
      _id
      price
      imageurl
      quantity
      options {
        size
        color
      }
    }
  }
`;

export const GET_PURCHASED_ITEMS = gql`
    query GetPurchasedItems($userID: ObjectId!, $purchaseId: String!) {
        carts(query: { userID: $userID, purchaseId: $purchaseId }) {
            userID
            beanies
            isPaid
            productId
            category
            beanies
            purchaseId
            timeStamp
            redeemIds
            name
            _id
            price
            imageurl
            quantity
            options {
                size
                color
            }
        }
    }
`;


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles(() => ({
  root: {
    padding: '10px',
    borderRadius: 20,
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
  },
  content: {
    padding: 10,
    spacing: 8,
  },
  brandCardHeader: {
    fontSize: 25,
    fontWeight: 600,
    textAlign: 'left',
    margin: '0px' /* or a smaller value than what's currently set */
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  button: {
    backgroundColor: '#077336',
    color: 'white',
    borderRadius: 20,
    padding: 10,
    fontWeight: 500,
    border: 'none',
    width: '50%',
    height: 40,
    marginTop: 1,
    '&:hover': {
      backgroundColor: '#3ff107',
    },
  },
}));

const UPDATE_CART_MUTATION = gql`
mutation UpdateOneCart($query: CartQueryInput, $set: CartUpdateInput!) {
  updateOneCart(query: $query, set: $set) {
    userID
      beanies
      productId
      name
      _id
      price
      imageurl
      quantity
      options {
        size
        color
      
    }
  }
}
`;

export const MARK_AS_PAID_MUTATION = gql`
  mutation MarkAsPaid($ids: [ObjectId!]!) {
  updateManyShopredeems(
    query: { _id_in: $ids }
    set: { isPaid: true }
  ) {
    modifiedCount
  }
}

`;

function createCoffeeBeanConfetti(options = {}) {
  const defaults = {
    scalar: 1.5,
    spread: 80,
    particleCount: 150,
    origin: { y: -0.1 },
    startVelocity: -40,
    zIndex: 1500,
  };

  // Merge provided options with defaults
  const mergedOptions = { ...defaults, ...options };

  // Define the coffee bean shape as an array of coordinate pairs
  const coffeeBeanShape = [
    [0.1, 0.1],
  [0.2, 0.2],
    
  ];

  confetti({
    ...mergedOptions,
    shape: coffeeBeanShape, // Use the custom coffee bean shape
    colors: ['#704214', '#704214', '704214']
  });
}


const PaymentSuccess = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const sessionId = new URLSearchParams(location.search).get("session_id");
  const classes = useStyles();
  const [lineItems, setLineItems] = useState([]);
  const [open, setOpen] = useState(true);
  const [createConfetti, setCreateConfetti] = useState(false); 
  const [hearts, setHearts] = useState([]);
  const isDarkMode = theme.palette.mode === 'dark';
  const [didAutoRedeem, setDidAutoRedeem] = useState(false);
  const [purchaseId, setPurchaseId] = useState(null);
  const [markAsPaid] = useMutation(MARK_AS_PAID_MUTATION);

  useEffect(() => {
    // Only generate once if you don’t have an ID yet
    if (!purchaseId) {
      const newId = uuidv4();
      setPurchaseId(newId);
    }
  }, [purchaseId]);

 
  useEffect(() => {
    const interval = setInterval(() => {
      const newHeart = {
        id: Math.random(),
        left: Math.random() * window.innerWidth,
        top: window.innerHeight,
      };
      setHearts((prevHearts) => [...prevHearts, newHeart]);

      setTimeout(() => {
        setHearts((prevHearts) =>
          prevHearts.filter((heart) => heart.id !== newHeart.id)
        );
      }, 2000);
    }, 200);

    return () => clearInterval(interval);
  }, []);

  const beanUrl = 'https://storage.googleapis.com/app_darkendimg/assets/madbean.png';
  
  
  const totalAmount = lineItems.reduce((acc, item) => acc + item.amount, 0);

  const FREE_SHIPPING_THRESHOLD = 4900; // $49.00 in cents
const SHIPPING_FEE_CENTS = 600;       // $6.00 in cents
  const hasPayoutItem = lineItems.some(
    (item) => item.category === 'Payout'
  );
  
  // Calculate final amount
  const finalAmount = hasPayoutItem
    ? totalAmount
    : totalAmount < FREE_SHIPPING_THRESHOLD
      ? totalAmount + SHIPPING_FEE_CENTS
      : totalAmount;
  
  const goToProfile = () => {
    
    setLineItems([]);
    
    navigate('/chat');
  };

  const { data: cartData, loading: cartLoading, error: cartError } = useQuery(GET_CART, {
    variables: { userID: user.id },
    skip: !user.id
  });

  const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER, {
    variables: { _id: user.id }
  });
  
  // Don’t do any final logic if user data is still loading or an error occurred

  const { data, refetch } = useQuery(GET_PURCHASED_ITEMS, {
    variables: { userID: user.id, purchaseId },
    skip: !purchaseId, // Only run once we have an ID
  });
  
  // After autoRedeemCart completes, you can:
  refetch();
  

  

  useEffect(() => {
    if (data && data.carts) {
      const newLineItems = data.carts.map(item => ({
        description: item.name,
        amount: item.price * item.quantity
      }));
      setLineItems(newLineItems);
    }
  }, [data]);

const [deleteOneItem] = useMutation(DELETE_ONE_ITEM);
  const [updateUserProf] = useMutation(UPDATE_USER_PROF);
  const [updateCart] = useMutation(UPDATE_CART_MUTATION, {
  refetchQueries: [
    // Replace this:
    // { query: GET_CART, variables: { userID: user.id, isPaid: true } }

    // With this, for example:
    { query: GET_PURCHASED_ITEMS, variables: { userID: user.id, purchaseId } },
  ],
});


const existingBeans = userData?.userprof?.beanCount || 0;
const cartItems = cartData?.carts || [];
const rewards = cartItems.map((i) => i.beanies || 0);
const totalRewards = rewards.reduce((acc, n) => acc + n, 0);
const newbeanCount = existingBeans + totalRewards;
  

//filet cart items by isPaid = false
// If cartData?.carts is undefined, fallback to an empty array:
//const cartItems = cartData?.carts?.filter((item) => !item.isPaid) || [];

const purchasedItems = data?.carts || [];
// The same logic as handleRedeem but extracted
// Example: your updated autoRedeemCart
const autoRedeemCart = async () => {
    try {
      // 1) Mark cart items as paid
      await Promise.all(
        cartItems.map(async (item) => {
          // First, mark the cart item as paid
          await updateCart({
            variables: {
              query: { _id: item._id, isPaid: false },
              set: {
                isPaid: true,
                purchaseId,
                timeStamp: new Date().toISOString(),
              },
            },
          });
  
          // 2) If this item has any shopredeems associated, mark them as paid too
          if (item.redeemIds && item.redeemIds.length > 0) {
            try {
              await markAsPaid({
                variables: {
                  ids: item.redeemIds, // array of shopredeem IDs
                },
              });
              console.log(
                `Marked ${item.redeemIds.length} shopredeems as paid for cart item:`,
                item._id
              );
            } catch (err) {
              console.error(
                `Error marking shopredeems as paid for cart item ${item._id}:`,
                err
              );
            }
          }
        })
      );
  
      // 3) Update user’s bean count
      await updateUserProf({
        variables: {
          query: { _id: user.id },
          set: { beanCount: newbeanCount },
        },
      });
  
      // (Optional) Manual refetch if needed
      // ...
  
      // 4) Confirm success or do confetti, redirect, etc.
      console.log("Auto-redeem complete");
    } catch (error) {
      console.error("Error auto-redeeming cart:", error);
    }
  };
  

// Run on page load, but only once
useEffect(() => {
    if (
      !userLoading &&
      !cartLoading &&
      !didAutoRedeem &&
      cartItems.length > 0
    ) {
      autoRedeemCart();
      setDidAutoRedeem(true);
    }
  }, [userLoading, cartLoading, didAutoRedeem, cartItems]);

// ...



const handleRedeem = async (cartId) => {
  try {
    // Update the user's bean count first
    await updateUserProf({
      variables: {
        query: { _id: user.id },
        set: { beanCount: newbeanCount }
      }
    });

    if (cartData && cartData.carts) {
      // Update the cart items to mark them as redeemed
      await Promise.all(
        data.carts.map((cartItem) =>
          updateCart({
            variables: {
              query: { _id: cartItem._id, isPaid: false},
              set: { isPaid: true, timeStamp: new Date().toISOString()}
            }
          })
        )
      );
    }
    // Show the bean rain dialog
   setCreateConfetti(true);

   // Trigger the confetti animation with the custom coffee bean shape
   createCoffeeBeanConfetti();

   // Hide the bean rain dialog after 1 second
   setTimeout(() => {
    setCreateConfetti(false);
    
   }, 3000)
    ;
  goToProfile();
    
    
  } catch (error) {
    console.error("Error redeeming cart:", error);
  }
};

const isSmartCitizen = userData?.userprof?.beanCount >= 4000;

  if (cartLoading || userLoading) {
    return <Progress />;
  }

  if (cartError || userError) {
    return <Typography>Error loading data: {cartError?.message || userError?.message}</Typography>;
  }

 

  return (

<React.Fragment  >
    {isMobile && (
     
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen sx={{padding: 0, }}>
     
   <Paper sx={{ padding: 2, height: '100vh', backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff', }}>
      {userData && userData.userprof && (
        <>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, flexDirection:  'column', }}>
            <Typography variant="h5"  sx={{ ml: 2, fontWeight: 700 , alignSelf: 'center' }}>
            Congratulations!
            </Typography>
            <Typography variant='body1' sx={{ fontWeight: 500 , alignSelf: 'center' }}>  Congratulations {totalRewards}k Tokens redeemed </Typography>
            </Box>
<Box sx={{ mb: 2, padding: 1 , mt: 2 }}>
<BeanCard userData={userData} isSmartCitizen={isSmartCitizen} />
</Box>
</>

      )}

<Card >
      <CardContent>
        
      <List>
      {purchasedItems.map((item) => (
        <ListItem key={item._id}>
            <Avatar alt={item.name} src={item.imageurl} />
            <ListItemText
                  primary={item.name}
                  secondary={`$${((item.price / 100) * item.quantity).toFixed(2)}`}
                />
            <ListItemSecondaryAction>
                <Chip label={`x${item.beanies}`} sx={{ backgroundColor: '#3ff107', color: 'black'}} />
            </ListItemSecondaryAction>

        </ListItem>
      ))}
    </List>
            
        
        
        <Box sx={{ display: 'flex', maxWidth: '400px', padding: 0 }}>
        <div className="App">
      {hearts.map((heart) => (
        <Heart key={heart.id} style={{ left: heart.left, top: heart.top }} beanUrl={beanUrl} />
      ))}
    </div> 
        
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', ml: 2 }}>
        <Typography variant="body2" >
        Your total: ${((finalAmount ?? 0) / 100).toFixed(2)}
            </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', ml: 2 }}>
          
            <Typography variant='body2' sx={{ fontWeight: 500 , }}>{totalRewards} Tokens</Typography> 
            </Box>
        </Box>
      </CardContent>
    </Card>

      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: 3 }}>
      
            <button className={classes.button} onClick={''} variant="contained"  sx={{ ml: 2 }}>
            <Typography variant='body1' sx={{ fontWeight: 500 , }}>  Redeem {totalRewards}k tokens </Typography> 
            </button>
          </Box>
          </Paper>
    </Dialog>
    
    )
  }

{userData && userData.userprof && (
        <>
       <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, flexDirection:  'column' }}>
            <Typography variant="h5"  sx={{ ml: 2, fontWeight: 700 ,  alignSelf: 'center' }}>
            Congratulations!
            </Typography>
            <Typography variant='body1' sx={{ fontWeight: 500 , alignSelf: 'center'  }}>  Congratulations {totalRewards}k Tokens redeemed </Typography>
            </Box>
<Box sx={{  mt: 0, mb: 2, padding: 5 ,  mt: 2}}>
<BeanCard userData={userData} isSmartCitizen={isSmartCitizen} />
</Box>
</>
      )}


        
           
            
        
       
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', ml: 2, mb: 2 }}>
            <Typography variant="body2">
              Your total: ${((finalAmount ?? 0) / 100).toFixed(2)}
            </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', ml: 2 }}>
          
            <Typography variant='body2' sx={{ fontWeight: 500 , }}>{totalRewards} Tokens</Typography> 
            </Box>
        </Box>
     

      <Box sx={{ display: 'flex', flexDirection: 'column',  justifyContent: 'center' }}>
           
            <button className={classes.button} onClick={''} variant="contained"  sx={{ ml: 2 }}>
            <Typography variant='body1' sx={{ fontWeight: 500 , }}>  Redeem {totalRewards}k tokens </Typography> 
            </button>
          </Box>
          
           

    
</React.Fragment>
  );
};

export default PaymentSuccess;
