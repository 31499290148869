import React from 'react';
import { useQuery, useMutation, } from '@apollo/client';
import { Chip, IconButton, Button, TextField, Dialog, DialogActions, Avatar, DialogContent, DialogContentText, DialogTitle, Typography, Box,
List, ListItem, ListItemAvatar, ListItemText, 
Grid} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { UserContext } from '../../../context/usercontext';
import GroupIcon from '@mui/icons-material/Group'; // icon for Collaborators
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // icon for Collaborating
import { useContext, useState } from 'react';
import gql from 'graphql-tag';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { useSpring, animated } from 'react-spring';

   

// GraphQL Queries
export const GET_COLLABORATING = gql`
 query GetCollaborating($userId: String!) {
  collaborates(query: { collaboratorID: $userId }) {
    _id
    collaboratingID
  }
}`;

export const GET_COLLABORATORS = gql`
query GetCollaborators($userId: String!) {
  collaborates(query: { collaboratingID: $userId }) {
    _id
    collaboratorID
  }
}`;

// GraphQL Mutations
const INSERT_ONE_COLLABORATE = gql`
mutation InsertOneCollaborate($data: CollaborateInsertInput!) {
  insertOneCollaborate(data: $data) {
    _id
    collaboratingID
    collaboratorID
    

  }
}`;

//UserProfiles
const GET_USER_PROFILES = gql`
query GetUserProfiles($_id : [ObjectId!]) {
  userprof(query: { _id: $_id }) {
    _id
  Nickname
  imageurl
  }
}`;



const DELETE_ONE_COLLABORATE = gql`
mutation DeleteOneCollaborate($query: CollaborateQueryInput!) {
  deleteOneCollaborate(query: $query) {
    _id
    collaboratingID
    collaboratorID
  }
}`;



const CollaboratorsDialog = ({ userIds, open, onClose }) => {
  const { loading, data } = useQuery(GET_USER_PROFILES, {
    variables: { userIds },
    skip: !userIds.length,
  });

  return (
    <Box>
      
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Collaborators</DialogTitle>
      <DialogContent>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <List>
            {data?.userProfiles.map(user => (
              <ListItem key={user._id}>
                <ListItemAvatar>
                  <Avatar src={user.avatar} />
                </ListItemAvatar>
                <ListItemText primary={user.name} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
    </Box>
  );
};

// CollaboratorsCount Component
export const CollaborateButton = ({ collaboratorId, collaboratingId, refetchQueries }) => {
  const { user } = useContext(UserContext);
  const [isBouncing, setIsBouncing] = useState(false);

  const bounce = useSpring({
    transform: isBouncing ? 'translateY(0px)' : 'translateY(-20px)',
    config: { tension: 500, friction: 10 },
    onRest: () => setIsBouncing(false),
  });

  const [insertOneCollaborate] = useMutation(INSERT_ONE_COLLABORATE, {
    variables: { data: { collaboratingID: collaboratingId, collaboratorID: collaboratorId } },
    refetchQueries,
  });

  const handleCollab = () => {
    setIsBouncing(true);
    insertOneCollaborate();
  };

  return (
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column', // Stack items vertically
      alignItems: 'center', // Center horizontally
      justifyContent: 'center', // Center vertically if needed
      padding: 0,
      borderRadius: 4,
    }}
  >
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <animated.div style={bounce}>
        <img
          src="https://storage.googleapis.com/app_darkendimg/assets/collab.png"
          alt="Collaborate"
          width="40"
          height="40"
        />
      </animated.div>
    </Box>
    <Typography
      onClick={handleCollab}
      variant="caption"
      sx={{ marginTop: '-20px' }} // Reduce the gap directly
    >
      Collaborate
    </Typography>
  </Box>
  


  );
};



// UncollaborateButton Component
export const UncollaborateButton = ({ collaboratorId, refetchQueries }) => {
  const { user } = useContext(UserContext);
  const [isBouncing, setIsBouncing] = useState(false);

  const bounce = useSpring({
    transform: isBouncing ? 'translateY(0px)' : 'translateY(-20px)',
    config: { tension: 500, friction: 10 },
    onRest: () => setIsBouncing(false),
  });

  const [deleteOneCollaborate] = useMutation(DELETE_ONE_COLLABORATE, {
    variables: { query: { collaboratingID: collaboratorId } },
    refetchQueries: [
      { query: GET_COLLABORATING, variables: { userId: collaboratorId } }, // Update collaborating list
      { query: GET_COLLABORATORS, variables: { userId: collaboratorId } }, // Update collaborators list
    ],
  });
  

  const handleClick = () => {
    console.log("Uncollaborating with:", collaboratorId);
    setIsBouncing(true);
    deleteOneCollaborate()
      .then(() => console.log("Refetch triggered successfully"))
      .catch((error) => console.error("Error in uncollaborate mutation:", error));
  };
  

  return (
   <Box
  sx={{
    display: 'flex',
    flexDirection: 'column', // Stack items vertically
    alignItems: 'center', // Center horizontally
    justifyContent: 'center', // Center vertically if needed
    padding: 0,
    borderRadius: 4,
  }}
>
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <animated.div style={bounce}>
      <img
        src="https://storage.googleapis.com/app_darkendimg/assets/collab.png"
        alt="Uncollaborate"
        width="40"
        height="40"
      />
    </animated.div>
  </Box>
  <Typography
    onClick={handleClick}
    variant="caption"
    sx={{ marginTop: '-20px' }} // Reduce the gap directly
  >
    Uncollaborate
  </Typography>
</Box>

  );
};
export const CollaboratorsCount = ({ userId }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const { loading: loadingCollaborating, error: errorCollaborating, data: dataCollaborating, refetch: refetchCollaborating } = useQuery(GET_COLLABORATING, {
    variables: { userId },
  });

  const { loading: loadingCollaborators, error: errorCollaborators, data: dataCollaborators, refetch: refetchCollaborators } = useQuery(GET_COLLABORATORS, {
    variables: { userId },
  });

  if (loadingCollaborating || loadingCollaborators) return <p>Loading...</p>;
  if (errorCollaborating || errorCollaborators) {
    return <p>Error: {errorCollaborating ? errorCollaborating.message : errorCollaborators.message}</p>;
  }

  const collaboratingCount = dataCollaborating ? dataCollaborating.collaborates.length : 0;
  const collaboratorsCount = dataCollaborators ? dataCollaborators.collaborates.length : 0;
  const userIds = [
    ...(dataCollaborating?.collaborates.map((c) => c.collaboratingID) || []),
    ...(dataCollaborators?.collaborates.map((c) => c.collaboratorID) || []),
  ];

  return (
    <Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around', // Distribute items evenly
        padding: 1,
        borderRadius: 0,
        width: '100%',
        gap: 2,
      }}
    >
      {/* Collabing Item */}
      <Box
        onClick={handleOpenDialog}
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack items vertically
          alignItems: 'center', // Center items horizontally
          gap: 0.5, // Space between icon and text
          cursor: 'pointer', // Add pointer cursor
        }}
      >
        <GroupIcon fontSize="large" /> {/* Icon */}
        <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
          Collabing
        </Typography>
        <Typography variant="body2">{collaboratingCount}</Typography> {/* Count */}
      </Box>
  
      {/* Collabers Item */}
      <Box
        onClick={handleOpenDialog}
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack items vertically
          alignItems: 'center', // Center items horizontally
          gap: 0.5, // Space between icon and text
          cursor: 'pointer', // Add pointer cursor
        }}
      >
        <GroupsOutlinedIcon fontSize="large" /> {/* Icon */}
        <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
          Collabers
        </Typography>
        <Typography variant="body2">{collaboratorsCount}</Typography> {/* Count */}
      </Box>
    </Box>
  
    <CollaboratorsDialog userIds={userIds} open={openDialog} onClose={handleCloseDialog} />
  </Box>
  
  );
};

export const CollaborateComponents = ({ userId }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
  <Grid container alignItems="center" spacing={2}>
    {/* Collaborators Count */}
    <Grid item xs={6} md={4}>
      <CollaboratorsCount userId={userId} />
    </Grid>

    {/* Collaborate and Uncollaborate Buttons */}
    <Grid item xs={6} md={8}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 1 }}>
        <CollaborateButton
          collaboratingId={userId}
          collaboratorId={userId}
          refetchQueries={[{ query: GET_COLLABORATING, variables: { userId } }]}
        />
        <UncollaborateButton
          collaboratorId={userId}
          refetchQueries={[
            { query: GET_COLLABORATING, variables: { userId } },
            { query: GET_COLLABORATORS, variables: { userId } },
          ]}
        />
      </Box>
    </Grid>
  </Grid>
</Box>

  );
}