import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Button, CardMedia, Box, Icon, Drawer, Chip, CardActions, Divider, Dialog, Paper, DialogActions, Avatar } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import showme from "../../../images/rita.jpg";
import { ArrowForward, ArrowRight, OutboundOutlined } from "@mui/icons-material";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import {Link} from 'react-router-dom';
import Dhome from "../../../pages/homedesk";
import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import beanify  from '../../../images/beaniefy.png';
import { useSpring, animated } from 'react-spring';
import { useNavigate } from "react-router-dom";
import Progress from "../../mods/progress";


export const MY_ADDS = gql`
  query MyAdds($isAdd: Boolean!) {
    aiadds(query: { isAdd: $isAdd }) {
      Title
      When
      _id
      description
      price
      primaryImage
      productID
      userID
      isAdd
      imageurl
      imageurl1
      sales_pitch
    }
  }
`;
 
export const GET_IMAGE = gql`
  query GetImage ($_id: ObjectId!){
    userprofs(query: { _id: $_id }) {
      imageurl
      _id
    }
  }
`;

const AiGen2 = () => {
  const [fact, setFact] = useState("");
  
  const { loading: loadingImage, error: errorImage, data: dataImage } = useQuery(GET_IMAGE, {
    variables: { _id: fact.userID,  },
  });

  const getimage = dataImage?.userprofs[0];
  
  const userimage = getimage?.imageurl || showme;
  const userid = getimage?._id;

  const { loading, error, data } = useQuery(MY_ADDS, {
    variables: { isAdd: true },
  });
  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [props, set] = useSpring(() => ({
    transform: 'scale(1)',
  }));

  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const [selectedItem, setSelectedItem] = useState(null);
const [isDialogOpen, setIsDialogOpen] = useState(false);
  

const handleDialogOpen = (item) => {
  setSelectedItem(item);
  setIsDialogOpen(true);
};

  const handleBounce = () => {
    set({ transform: 'scale(1.2)' });
    setTimeout(() => {
      set({ transform: 'scale(1)' });
    }, 300); // Reset to original scale after 2 seconds
  };

  useEffect(() => {
    if (data && data.aiadds.length > 0) {
      generateFact();  // Generate an initial fact
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      generateFact();
    }, 3000); // 3 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [data]); // Make sure to include data in the dependency array
 
  const generateFact = () => {
    if (data && data.aiadds.length > 0) {
      const addsWithYes = data.aiadds.filter(item => item.isAdd); // Filter for isAdd === true
      if (addsWithYes.length > 0) {
        const randomIndex = Math.floor(Math.random() * addsWithYes.length);
        setFact(addsWithYes[randomIndex]); // Use a fact from the filtered list
      }
    }
  };

 

  if (loading) return <p><Progress/></p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', padding: 1 }}>
  
  <Typography variant="body2" sx={{ fontWeight: 600, textAlign: 'left',   }}>
   Smart Market
 </Typography>
   </Box>
   <Card sx={{   boxShadow: 0, paddingBottom: 0 }}>
  
   
  
            
 
  {fact && (
  <>
   
    <Box 
      position="relative" 
      sx={{
        display: 'flex',
        borderRadius: 0,
        overflow: 'hidden', // Ensures the child components adhere to the border radius
        zIndex: 1000, // Ensures the box is behind the absolute-positioned box
      }}
    >
 
      <CardMedia
        component="img"
        height='200px'
        width='100px'
        image={fact.primaryImage}
        alt="Story image"
       
        sx={{ 
          width: '100%', // Ensures the image covers the card width
          
          objectFit: 'cover', // Ensures the image covers the area without distortion
        }}
      />
      <Box 
        position="absolute" 
        top={2} 
        left={0} 
        right={0} // Added to ensure the box takes the full width
         
        p={1}
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack children vertically
          justifyContent: 'space-between', // Space between top and bottom elements
          
        }}
      >
        
        
        
      
      </Box>
      <CardContent sx={{padding:1, paddingBottom: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', padding: 0 }}>
      <Typography 
          variant="heading" 
          component="div" 
          sx={{
            
            fontWeight: 700,
            mb: 'auto', // Pushes the rest of the content to the 
            padding: 0,
          }}
        >
          {fact.Title}
         
        </Typography>
        <Link to={`/kupster/${userid}`} key={userimage._id}>
            <Avatar src={userimage} alt="beanify" style={{width: 40, height: 40}} />
            </Link>
        </Box>
      <Typography 
           variant="heading"
           sx={{
             mt: 0,
             fontWeight: 400,
              padding: 0,
             alignSelf: 'flex-end',
             display: '-webkit-box',
             WebkitLineClamp: 3,
             WebkitBoxOrient: 'vertical',
             overflow: 'hidden',
             textOverflow: 'ellipsis',
           }}
        >
          {fact.sales_pitch}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', paddingTop: 2 }}>
        <Typography variant="body1" sx={{ color: 'success', fontWeight: 500 }}>
  {(fact.price / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
</Typography>
          
          <Chip label='Learn More' onClick={() => setIsDrawerOpen(true)} color='success' />
          </Box>
          
        </CardContent>
    </Box>
  </>
)}
 
 
  
</Card>
<Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} sx={{ width: '100%', padding: 0, zIndex: 3500 }}>
  <Box sx={{ dispplay: 'flex', justifyContent: 'flex-start', padding: 0 }}>
    
    <Card sx={{ 
    display: 'flex', 
    width: 'calc(100% + 10px)', // Adjust the calculation based on actual body padding
          
    backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/path/to/destination/koffiecandy.jpeg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: 200,
    borderRadius: 0,
    boxShadow: 0,
    paddingTop: 0,
    margin: 0,
    overflow: 'hidden', // Ensure no scrollbars appear due to negative margins
  }}>
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: 0 }}>
    
      <ArrowBackIcon onClick={handleDrawerClose} sx={{ color: " white" }} />
   
    </Box>
    <CardContent>
    <Typography variant="h4" sx={{ fontWeight: 700,  textAlign: 'left', paddingTop: 0, textShadow: '2px 2px 4px #000000' , color: 'white' }} >
      Create your Online Store 
    </Typography>
    <Typography variant="h6" sx={{ fontWeight: 600,  textAlign: 'left', paddingRight: 2, paddingTop: 1, textShadow: '2px 2px 4px #000000' , color: 'white' }} >
    Let our AI salesperson sell 
    
    <Link to="/chat" style={{ textDecoration: 'none' }}>
    <Chip  color='success' label="Go to Store"  />  
    </Link>
    </Typography>
    </CardContent>
  </Card>
  </Box>
  <Box
  sx={{
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 56px)',
    paddingTop: 2,
  }}
>
  {data &&
    data.aiadds.map((item) => (
      <Card
        key={item._id}
        sx={{
          display: 'flex',
          padding: 1,
          margin: 1,
          borderRadius: 2, // Added border radius for a smoother look
          boxShadow: 1, // Subtle shadow for depth
        }}
      >
        <CardMedia
          component="img"
          height="120px"
          width="120px"
          image={item.primaryImage}
          alt="Story image"
          sx={{
            width: '120px', // Fixed width for the image
            height: '120px', // Fixed height for the image
            objectFit: 'cover', // Ensures the image covers the area without distortion
            borderRadius: 2, // Rounded corners for the image
          }}
        />
        <CardContent
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                flex: 1,
                marginRight: 1,
              }}
            >
              {item.Title}
            </Typography>
            <Chip
              label="Details"
              onClick={() => handleDialogOpen(item)}
              sx={{
                backgroundColor: 'primary.main',
                color: 'white',
                fontWeight: 600,
              }}
            />
          </Box>
          <Typography
            variant="body2"
            sx={{
              mt: 1,
              fontWeight: 400,
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {item.sales_pitch}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <Avatar
              src={item.imageurl1}
              alt="beanify"
              sx={{ width: 30, height: 30 }}
            />
            <Chip
              label={(item.price / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
              sx={{
                fontWeight: 700,
                backgroundColor: 'success.light',
                color: 'white',
              }}
            />
          </Box>
        </CardContent>
      </Card>
    ))}

  {/* Dialog */}
  <Dialog
    fullScreen
    open={isDialogOpen}
    onClose={() => setIsDialogOpen(false)}
    sx={{ boxShadow: 0, zIndex: 5000 }}
  >
    <IconButton
      onClick={() => setIsDialogOpen(false)}
      sx={{ position: 'absolute', left: 0, top: 0 }}
    >
      <ArrowBackIcon />
    </IconButton>
    <Box sx={{ padding: 2 }}>
      <CardMedia
        component="img"
        height="150px"
        width="150px"
        image={selectedItem?.primaryImage}
        alt="Story image"
        sx={{
          width: '100%',
          height: '150px',
          objectFit: 'cover',
          borderRadius: 2,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, textAlign: 'left' }}
        >
          {selectedItem?.Title || 'AI Adds'}
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, textAlign: 'right' }}
        >
          {(selectedItem?.price / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{ fontWeight: 300, textAlign: 'left', mt: 1 }}
      >
        {selectedItem?.sales_pitch || 'This is a dialog box'}
      </Typography>
      <DialogActions sx={{ mt: 2 }}>
        <Chip
          label="Get It"
          color="success"
          onClick={() => navigate('/product/' + selectedItem?.productID)}
          sx={{
            fontWeight: 600,
            fontSize: '1rem',
            padding: '8px 16px',
          }}
        />
      </DialogActions>
    </Box>
  </Dialog>
</Box>
   
  </Drawer>

</>
  );
};

export default AiGen2;
