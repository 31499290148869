import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Card, Avatar, Typography, Grid, LinearProgress, Button, 
  Fab, Dialog, DialogActions, Chip, DialogTitle, DialogContent, CardContent, DialogContentText, 
Drawer, CardActions, 
} from '@mui/material';
import { gql } from '@apollo/client';


import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale , } from 'chart.js/auto'; 
// Register the moment adapter
import moment from 'moment';
import { registerAdapterDateFns } from 'chartjs-adapter-moment'; 
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import Progress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { UserContext } from '../../context/usercontext';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import kcoin from '../../images/kcoin.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GaugeChart from 'react-gauge-chart'
import Flavloader from './flavloader';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Chron from '../../pages/chron';

import PriceTicket from '../finance/prods/pricefeed';
import BeanCard from './beancard';
import GoalCard from '../finance/adds/goalcard';


const coffeebeans = 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png';
const minted = 'https://storage.googleapis.com/app_darkendimg/assets/kbean.png';
const koffie = 'https://storage.googleapis.com/app_darkendimg/assets/cherrybranch.png';

const useStyles = makeStyles((theme) => ({
  progressAndChart: {
    display: 'flex', 
    
  },
  progressContainer: {
    width: 300, 
    height: 30,
    border: '1px solid #ddd', 
    borderRadius: 5,
  },
  progressFiller: {
    backgroundColor: 'lightgreen', 
  },
  chartContainer: {
     width: '100%',
  },
  chart: {
    height: 400,
  },
  progressText: {
    textAlign: 'center',
    fontWeight: 700,
  },
  progressPercent: {
    fontWeight: 700,
    color: 'green',
  },
  progressLabel: {
    fontWeight: 400,
    color: 'black',
  },
  progressChip: {
    borderColor: 'green',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: 'white',
  },
  progressChipLabel: {
    color: 'green',
  },
  progressChipIcon: {
    color: 'green',
  },
  progressChipContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 2,
  },
  progressChipText: {
    fontWeight: 400,
    color: 'green',
  },
  progressChipValue: {
    fontWeight: 700,
    color: 'green',
  },
  progressChipIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 1,
  },
  progressChipLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressChipValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressChipTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  needlelenth: {
    width: 400,
  },
}));




// Register the moment adapter

ChartJS.register(CategoryScale);


function formatBeanCount(number) {
  if (number < 1000) {
    return number; // return the same number if less than 1000
  } else if (number < 1000000) { // for numbers between 1000 and 999,999
    return (number / 1000).toFixed(1) + 'k'; // one decimal place with 'k'
  } else {
    return (number / 1000000).toFixed(1) + 'M'; // one decimal place with 'M' for numbers 1,000,000 and above
  }
}




const TopBean = () => {
  const navigate = useNavigate();
 

  
  const { user } = useContext(UserContext);
  const { Nickname, beanCount, imageurl, _id } = user.customData
  const classes = useStyles();
  const [chartReady, setChartReady] = useState(false); 
  
  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  

  const handleNavigate = () => {
    navigate('/store');
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  
  
 
  

  
  // Ensure data.userprof exists and is an array, then sort by beanCount and slice top 5
  

  



  
  return (
    <>
   

   <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
        borderRadius: 0,
        boxShadow: 0,
        marginTop: 0,
        position: 'relative',
        width: '100%', // Ensures full viewport width
        height: '60vh',
        zIndex: 1,
        overflow: 'hidden', // Prevents overflow
      }}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      >
        <source src="https://storage.googleapis.com/app_darkendimg/assets/pick1.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      
     
          <Box 
            sx={{
              position: 'absolute',
              bottom: 30,
              left: 10,
            }}>
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={12} md={12} container direction="column" alignItems="left" sx={{ padding: 0 }}>
          <Typography variant="h2" sx={{  fontWeight: 800, color: 'white', paddingTop: 0, mb: 0, mt: 30, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.9)' }}>
            Koffie
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 600, color: 'white', textShadow: '2px 2px 8px rgba(0, 0, 0, 0.9)' }}>
            The Original Red Pill
          </Typography>
          <Button  onClick={handleNavigate} sx={{ marginTop: 1, backgroundColor: "#ffc107", border: 'solid 1px #000', borderRadius: 10, padding: 0, width: 150 }}>
            <Typography variant="h6" sx={{ fontWeight: 700, color: '#000', textShadow: '2px 2px 8px rgba(0, 0, 0, ,0.9)'  }}>
               Get Koffie
            </Typography>
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Box>
    
     
    
    </>
  );
};

export default TopBean;
