import React, { useRef } from 'react';
import { Box, CardMedia } from '@mui/material';

const DailyGrindMediaItem = ({ item, title }) => {
  const isVideo = /\.(mp4|webm|mov)$/i.test(item);
  const videoRef = useRef(null); // Ref for the video element

  return (
    <Box sx={{ marginBottom: '10px', width: '100%' }}>
      {isVideo ? (
        <video
          ref={videoRef}
          controls
          autoPlay // Automatically starts playing the video
          
          style={{ borderRadius: 0, width: '100%', padding: 0 }}
          playsInline // Prevents iOS from forcing fullscreen
        >
          <source src={item} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      ) : (
        <CardMedia
          component="img"
          image={item}
          alt={`${title}`}
          sx={{
            borderRadius: 0,
            padding: 0,
            objectFit: 'contain',
            width: '100%',
            height: 'auto',
          }}
        />
      )}
    </Box>
  );
};

export default DailyGrindMediaItem;
