import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Card, Grid, CardMedia, IconButton, List, ListItem, ListItemText, Divider, Drawer, Chip, Tabs, Tab, CardActionArea,
CardContent

 } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/usercontext';
import { useMutation } from '@apollo/client';
import { TextField, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { UPDATE_USER_PROF, GET_USER } from '../../../context/gqllogics';
import ShopRedeemList from '../beanapi/shopreport';
import { useTheme } from '@mui/material/styles';
import { Shop } from '@mui/icons-material';
import DrinkRedeemList from '../beanapi/drinksredeemed';
import { Close } from '@mui/icons-material';

export const GET_CART = gql`
  query GetCart($userID: ObjectId!) {
    carts(query: { userID: $userID, isPaid: true }) {
      userID
      beanies
      isPaid
      productId
      prodowner
      timeStamp
      name
      _id
      price
      imageurl
      quantity
      options {
        size
        color
      }
    }
  }
`;

export const GET_PAID_CART_BY_OWNER = gql`
  query GetPaidCartByOwner($prodowner: ObjectId!) {
    carts(query: { prodowner: $prodowner, isPaid: true }) {
      _id
      name
      price
      quantity
      isPaid
      productId
      prodowner
      timeStamp
      imageurl
      purchaseId
      options {
        size
        color
      }
    }
  }
`;


const CashAppIcon = () => {
  return (
    <img src="https://storage.googleapis.com/app_darkendimg/assets/assets_chashapp.png" alt="beans" style={{ width: 40, height: 40, background: 'none' }} />
  );
};

const ZelleIcon = () => {
  return (
    <img src="https://storage.googleapis.com/app_darkendimg/assets/zelleasset.png" alt="beans" style={{ width: 40, height: 40, background: 'none' }} />
  );
};

function BeanReport({prodowner}) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [itemsInBag, setItemsInBag] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [payout, setPayout] = useState('');
  const [cashappid, setCashAppId] = useState('');
  const [zelleid, setZelleId] = useState('');
  const [showPayoutForm, setShowPayoutForm] = useState(false);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    variables: { _id: user.id },
    skip: !user.id,
  });

  // set a constant for usertype coffeeShop is koffieshop
  const isKoffieShop = userData?.userprof.usertype === 'koffieshop';


  useEffect(() => {
    if (userData && userData.userprof) {
      setCashAppId(userData.userprof.cashappid || '');
      setZelleId(userData.userprof.zelleid || '');
      setPayout(userData.userprof.payout || '');
    }
  }, [userData]);

  const [updateUserProf] = useMutation(UPDATE_USER_PROF);

  const updatePayout = async (payout) => {
    try {
      await updateUserProf({
        variables: {
          query: { _id: user.id },
          set: { payout, cashappid: cashappid, zelleid: zelleid},
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  


  const handleDrawerOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const { data: cartData, loading: cartLoading, error: cartError } = useQuery(GET_CART, {
    fetchPolicy: 'cache-and-network',
    variables: { userID: user.id },
    skip: !user.id,
  });
 
  const { loading, error, data } = useQuery(GET_PAID_CART_BY_OWNER, {
    fetchPolicy: 'cache-and-network',
    variables: { prodowner: user.id },
    skip: !user.id,
  });

  
  //Filter items by Product ID eqaul to user.id and count the total price

 const filteredItems = cartData?.carts.filter((item) => item.prodowner === user.id);
 const totalItems = filteredItems?.reduce((acc, item) => acc + item.price, 0);
 
 const takehome = totalItems * 0.9;
 
  useEffect(() => {
    if (cartData && cartData.carts) {
      const newItemsInBag = cartData.carts.map((item) => {
        return {
          name: item.name,
          price: item.price,
          imageurl: item.imageurl,
          farmer: item.farmer,
          _id: item._id,
          quantity: item.quantity,
          isPaid: item.isPaid,
          beanies: item.beanies,
          timeStamp: item.timeStamp,
          productId: item.productId,
         
        };
      });
      setItemsInBag(newItemsInBag);
    }
  }, [cartData]);
  

  const total = itemsInBag.reduce((acc, item) => acc + item.price, 0);
  const totalQuantity = itemsInBag.reduce((acc, item) => acc + item.quantity, 0);
  const totalBeanies = itemsInBag.reduce((acc, item) => acc + item.beanies, 0);
  
  const handleSave = async () => {
    const updatedFields = {};

    if (cashappid) {
      updatedFields.cashappid = cashappid;
    }
    if (zelleid) {
      updatedFields.zelleid = zelleid;
    }
    if (payout) {
      updatedFields.payout = payout;
    }

    try {
      await updateUserProf({
        variables: {
          query: { _id: user.id },
          set: updatedFields,
        },
      });
      setShowPayoutForm(false);
    } catch (error) {
      console.error(error);
    }
  };


  const isSaveDisabled = !cashappid && !zelleid;
 
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  if (!data || !data.carts || data.carts.length === 0) {
    return <p>No paid items found for this product owner.</p>;
  }

  const totalGross1 = data.carts.reduce((acc, item) => acc + (item.price * item.quantity), 0) / 100;
  const totalNet = (totalGross1 * 0.94).toFixed(2); // Deducting 6% service fee


  return (
    <Box sx={{ mt: 0, display: 'flex', flexDirection: 'column', gap: 1, mb: 10, padding: 2 }}>
<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0, mt: 1, gap: 1 }}>
      
      <Typography variant="body1" sx={{ fontWeight: 700, }}>
        Total Sales
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.socondary' }}>
        ${totalItems}
      </Typography>
      <>
<Button
  onClick={() => setShowPayoutForm(!showPayoutForm)}
  sx={{
    borderRadius: 2,
    border: 'solid 1px #077336',
    alignSelf: 'flex-end',
    backgroundColor: 'transparent',
    textTransform: 'none', // Prevents uppercase transformation
    color: isDarkMode ? '#fff' : '#077336', // Dark mode: white text, Light mode: black text
  }}
>
  {showPayoutForm ? 'Hide Payout Methods' : 'Payment Methods'}
</Button>
</>
    </Box>
    <>            
          {showPayoutForm && (
            <Card sx={{ boxShadow: 0, borderRadius: 0, paddingBottom: 0, mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 700, mt: 0, ml: 2 }}>Payout</Typography>
              <Typography variant="body1" sx={{ ml: 2, mt: 2 }}>We currently pay out to CashApp and Zelle.</Typography>
              <Typography variant="caption" sx={{ ml: 2, mt: 2 }}>Stay tuned for more payment options.</Typography>

              <Grid container spacing={2} sx={{ mt: 2, p: 2 }}>
                <Grid item xs={12}>
                  <TextField
                  sx={{ paddingLeft: 0}}
                    label="CashApp ID"
                    size='small'
                    value={cashappid}
                    onChange={(e) => setCashAppId(e.target.value)}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <IconButton position="start" sx={{backgroundColor: 'none', paddingLeft: 0}}>
                          <CashAppIcon />
                        </IconButton>
                      ),
                      inputProps: {
                        style: {
                          textAlign: "right",
                          width: '100%',
                          borderRadius: '0px',
                          marginBottom: 0,
                          backgroundColor: '#bce3c0'
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                  sx={{ paddingLeft: 0}}
                    label="Zelle ID"
                    size='small'
                    value={zelleid}
                    onChange={(e) => setZelleId(e.target.value)}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <IconButton position="start" sx={{backgroundColor: 'none', paddingLeft: 0}}>
                          <ZelleIcon />
                        </IconButton>
                      ),
                      inputProps: {
                        style: {
                          textAlign: "right",
                          width: '100%',
                          borderRadius: '0px',
                          marginBottom: 0,
                          backgroundColor: '#bce3c0',
                          paddingLeft: '0px'
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>Select Default Payout Method:</Typography>
                  <RadioGroup
                    aria-label="payout"
                    name="payout"
                    value={payout}
                    onChange={(e) => setPayout(e.target.value)}
                  >
                    <FormControlLabel value="cashapp" control={<Radio />} label="CashApp" />
                    <FormControlLabel value="zelle" control={<Radio />} label="Zelle" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    
                    onClick={handleSave}
                    disabled={isSaveDisabled}
                    sx={{ mt: 1, backgroundColor: '#077336', color: 'white', fontWeight: 600, borderRadius: 20, border: 'solid 0px #077336', alignSelf: 'flex-end'}}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Card>
          
          )}

          </>
    <Divider />
    
        <Box sx={{ padding: 1 }}>
      {/* 🏷 Revenue Heading */}
      <Typography variant="body1" sx={{ fontWeight: 700, marginBottom: 2 }}>
        Paid Orders from Your Products
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: 600, color: 'green' }}>
        Total Gross: ${totalGross1.toFixed(2)} | Net Revenue (after 6% fee): ${totalNet}
      </Typography>

      {/* 📜 Scrollable Box */}
      <Box sx={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex', gap: 2, padding: 2 }}>
        {data.carts.map((item) => (
          <Card key={item._id} sx={{ minWidth: 150, maxWidth: 280, display: 'inline-block' }}>
            <CardMedia
              component="img"
              height="160"
              image={item.imageurl}
              alt={item.name}
              sx={{ objectFit: 'cover' }}
            />
            <CardContent>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                {item.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Price: ${(item.price / 100).toFixed(2)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Quantity: {item.quantity}
              </Typography>
            </CardContent>
            <CardActionArea sx={{ padding: 1 }}>
            <Chip
  label="View Details"
  onClick={() => handleDrawerOpen(item)}
  sx={{
    backgroundColor: '#077336', // Custom green color
    color: 'white',             // Ensures text is readable
    textTransform: 'none',      // Removes ALL CAPS
    fontSize: '14px',           // Adjusts text size
    padding: '8px 12px',        // Adds comfortable spacing
    '&:hover': { backgroundColor: '#065a2a' } // Darker shade on hover
  }}
/>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Box>
   <Tabs
  variant="contained"
  value={tabIndex}
  onChange={handleTabChange}
  sx={{
    borderBottom: 1,
    borderColor: "divider",
    "& .MuiTabs-indicator": {
      backgroundColor: "#077336", // Custom indicator color
    },
  }}
>
  <Tab
    label="DigiBeans"
    sx={{
      width: "33%",
      textTransform: "none", // Remove uppercase
      fontSize: "0.875rem",
      fontWeight: 500,
     
      "&.Mui-selected": {
        color: "#077336", // Selected tab color
      },
    }}
  />
  <Tab
    label="Purchases"
    sx={{
      width: "33%",
      textTransform: "none",
      fontSize: "0.875rem",
      fontWeight: 500,
      
      "&.Mui-selected": {
        color: "#077336",
      },
    }}
  />
  <Tab
    label="Rewards "
    sx={{
      width: "33%",
      textTransform: "none",
      fontSize: "0.875rem",
      fontWeight: 500,
      
      "&.Mui-selected": {
        color: "#077336",
      },
    }}
  />
</Tabs>


    {tabIndex === 0 && (
      
              <ShopRedeemList />
    )}

    {tabIndex === 1 && (
      <Box sx={{ mt: 2 , display: 'flex', flexDirection: 'column', gap: 1 }}>


<Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant='body1' sx={{ fontWeight: 500 }}>Total tokens </Typography>
          <Typography variant='body1' sx={{ fontWeight: 500 }}> {totalBeanies}</Typography>

          </Box>

          <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant='body1' sx={{  fontWeight: 500 }}>Total Spent: </Typography>
          <Typography variant='body1' sx={{  fontWeight: 500 }}> ${total}</Typography>
        </Box>

        <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
<Typography variant='body1' sx={{  fontWeight: 500 }}>Total lbs</Typography>
<Typography variant='body1' sx={{  fontWeight: 500 }}> {totalQuantity}</Typography>
</Box>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Purchases:
        </Typography>
        <List sx={{ mt: 4 }}>
  {itemsInBag && itemsInBag.length > 0 ? (
    itemsInBag.map((item, index) => (
      <Box key={index}>
        <ListItem sx={{ display: 'flex', alignItems: 'center' }}>
          <CardMedia
            component="img"
            image={item.imageurl}
            alt={item.name}
            sx={{ width: 60, height: 60, objectFit: 'cover' }} // Set appropriate width and height
          />
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box>
                  <Typography variant="body1">{item.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    ${item.price.toFixed(2)}
                  </Typography>
                </Box>
                <Chip 
                  label={`x${item.beanies}`} 
                  sx={{ backgroundColor: '#077336', color: 'white', ml: 2 }} 
                  onClick={() => handleDrawerOpen(item)} 
                />
              </Box>
            }
            sx={{ marginLeft: 2 }}
          />
        </ListItem>
        {index < itemsInBag.length - 1 && <Divider />}
      </Box>
    ))
  ) : (
    <Typography>No Purchese yet.</Typography>
  )}
</List>

      </Box>
    )}

    {tabIndex === 2 && (
      <DrinkRedeemList />
    )}

    <Drawer anchor="right" open={open} onClose={handleDrawerClose}
    sx={{  width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1500 }}
    >
      <IconButton onClick={handleDrawerClose} sx={{ position: 'absolute', top: 0, left: 0 }}>
        <Close />
      </IconButton>
      {selectedItem && (
        <Box sx={{ width: 400, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 5, mb: 2 , padding: 2}}>

          <Typography variant="h4" fontWeight={700}>{selectedItem.name}</Typography>
          <img src={selectedItem.imageurl} alt={selectedItem.name} style={{ width: '100%', marginTop: 16 , borderRadius: 5}} />
            <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant="h6" color="textSecondary" sx={{ fontWeight: 500 }}>Beanies: {selectedItem.beanies}</Typography>
          </Box>
          <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant="body2" color="textSecondary">Gross: </Typography>
          <Typography variant="body2" color="textSecondary">${selectedItem.price.toFixed(2)}</Typography>
          </Box>
          <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant="body2" color="textSecondary"> 6% of Net Margin: </Typography>
          <Typography variant="body2" color="textSecondary">${(selectedItem.price * 0.06).toFixed(2)}</Typography>
          </Box>
          <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant="body2" color="textSecondary">Net Margin: </Typography>
          <Typography variant="body2" color="textSecondary">${(selectedItem.price * 0.94).toFixed(2)}</Typography>
          </Box>
          <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant="body2" color="textSecondary">Product ID: </Typography>
          <Typography variant="body2" color="textSecondary">{selectedItem.productId}</Typography>
          </Box>
          <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant="body2" color="textSecondary">Timestamp:</Typography>
          <Typography variant="body2" color="textSecondary">{selectedItem.timeStamp}</Typography>
          </Box>
        </Box>
      )}
    </Drawer>
  </Box>
  );
}

export default BeanReport;
