import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Typography, Box, Card, Button, Divider , CardContent, Chip, Icon, IconButton} from '@mui/material';
import { styled } from '@mui/system';
import DailyGrindMediaItem from '../components/posts/DailygrindMedia';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Skeleton } from '@mui/material';

const GET_VIDEOS = gql`
  query {
    videos(sortBy: CREATEDAT_ASC) {
      _id
      Title
      description
      videourl
      summary
      cover
    }
  }
`;

const ScrollableContainer = styled(Box)({
  display: 'flex',
  overflowX: 'scroll',
  padding: '4px',
  gap: '16px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none', /* IE and Edge */
  'scrollbar-width': 'none', /* Firefox */
});

const StoryCard = styled(Card)({
  width: '200px',
  height: 'auto',
  flex: '0 0 auto',
  overflow: 'hidden',
  cursor: 'pointer',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ImageContainer = styled('div')({
  width: '100%',
  height: 'auto',
  aspectRatio: '16/9',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px',
});

const TextContainer = styled('div')({
  padding: '8px',
  fontSize: '14px',
  fontWeight: 800,
  textAlign: 'left',
  alignSelf: 'flex-start', // Aligns the text to the left within the card
});

function DailyGrindDesk() {
  const { loading, error, data } = useQuery(GET_VIDEOS);
  const [selectedVideo, setSelectedVideo] = useState(null); // Selected video
  const [expanded, setExpanded] = useState(false);  // To manage summary expansion
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleCardClick = (video) => {
    setSelectedVideo(video);
  };

  const handleBackClick = () => {
    setSelectedVideo(null); // Clear the selected video
  };

  const summary = selectedVideo?.summary || '';
  const summaryPreview = summary.length > 150 ? `${summary.slice(0, 150)}...` : summary;

  

  if (loading) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, gap: 2 }}>
  <Skeleton variant="rectangular" width="1100%" height={160} sx={{ borderRadius: 2 }} />
  
  </Box>
  if (error) return <div>Error fetching data</div>;

  const renderSelectedVideo = () => (
    <Box sx={{ marginBottom: 2 }}>
      {selectedVideo && (
        <>
          <Box sx={{ 
  display: 'flex', 
  justifyContent: 'flex-end', // Align items to the right (flex-end)
  alignItems: 'center', 
  padding: 1, 
  position: 'relative' // To allow absolute positioning of the IconButton within
}}>
  <IconButton
    onClick={handleBackClick} 
    sx={{ 
      position: 'absolute', 
      left: 0, 
      top: 0 
    }}
    variant="outlined"
  >
    <ArrowBackIcon />
  </IconButton>
  <Typography 
    variant="h6" 
    sx={{ 
      fontWeight: 800, 
      marginLeft: 'auto' // Push the text to the right
    }}
  >
    {selectedVideo?.Title || 'Untitled'}
  </Typography>
</Box>
          <DailyGrindMediaItem
            key={selectedVideo._id}
            item={selectedVideo.videourl}
            title={selectedVideo.Title}
          />
            {/* Video description and summary */}
            <Box sx={{ marginTop: 1, zIndex: 1400 }}>
                <Typography variant="h4" sx={{ fontWeight: 900, textAlign: 'left' }}>
                  {selectedVideo?.Title || 'Untitled'}
                </Typography>
                <Card sx={{ marginTop: 0, padding: 0 }}>
                  <Divider />
                  <CardContent sx={{ padding: 1 }}>
                    <Typography variant="body2" sx={{ marginBottom: 1 }}>
                      {expanded ? summary : `${summaryPreview}...`}
                    </Typography>
                    <Chip label={expanded ? 'Less' : 'More'} onClick={toggleExpand} />
                   
                  </CardContent>
                </Card>
              </Box>
        </>
      )}
    </Box>
  );

  return (
    <div>
        <Divider />
      {/* Display selected video */}
      {renderSelectedVideo()}
        
      {/* Scrollable video container */}
      <ScrollableContainer>
        {/* Render all videos except the selected one */}
        
        {data.videos
          .filter((video) => selectedVideo?._id !== video._id) // Exclude the selected video
          .map((video) => (
            <StoryCard
              key={video._id} // Ensure unique key
              onClick={() => handleCardClick(video)}
            >
              <ImageContainer
                style={{
                  backgroundImage: `url(${video.cover})`,
                }}
              />
              <TextContainer>{video.Title}</TextContainer>
            </StoryCard>
          ))}
      </ScrollableContainer>

      <Divider sx={{ marginTop: 2 }} />
    </div>
  );
}

export default DailyGrindDesk;
