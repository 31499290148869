import { useEffect, useContext, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserContext } from "../context/usercontext";
import AppLayout from "../pages/Layout";
import Progress from "../components/mods/progress";

const PrivateRoute = (props) => {
  const { user, fetchUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      fetchUser()
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to fetch user:", error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [user, fetchUser]);

  if (isLoading) {
    return <div><Progress /></div>;
  }

  return user ? (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
};

export default PrivateRoute;
