import React, { useState, useContext } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { Box, Button, Drawer, TextField, Typography, Grid, Card, CardContent, Avatar, CardMedia, Chip, CardActions } from '@mui/material';
import { UserContext } from '../../../context/usercontext';
import useFileUpload from '../cloud';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import styled from "styled-components";

const ADD_REWARD = gql`
  mutation AddReward($data: RewardInsertInput!) {
    insertOneReward(data: $data) {
      _id
      Title
      beans
      imageurl
      available
      description
      shopID
    }
  }
`;

const DELETE_ONE_REWARD = gql`
  mutation DeleteOneReward($query: RewardQueryInput!) {
    deleteOneReward(query: $query) {
      _id
    }
  }
`;

const MY_REWARDS = gql`
  query MyRewards($shopID: ObjectId!) {
    rewards(query: { shopID: $shopID }) {
      _id
      Title
      beans
      imageurl
      available
      description
      shopID
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  border-radius: 0px;
  padding: 2px;
  margin: 16px 0;
  
`;

const UsersList = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 1px;
  gap: 8px; // Adjust the gap as needed
  
  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none;  // IE and Edge
  scrollbar-width: none;  // Firefox
`;


const UserCard = styled.div`
  
  
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); // This adds a subtle shadow to the card
  text-decoration: none;
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 1px; // Adjust the gap as needed
 
`;

const AddRewardComponent = () => {
  const { user } = useContext(UserContext);
  const { file, previewURL, handleFileChange, uploadFile, handleRemoveImage, handleRemoveFile  } = useFileUpload(); // Use your custom hook
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = React.createRef();
  const isVideo = previewURL && (previewURL.endsWith('.mp4') || previewURL.endsWith('.mov'));

  const [openDrawer, setOpenDrawer] = useState(false);
  const [newReward, setNewReward] = useState({
    Title: '',
    beans: '',
    imageurl: '',
    description: '',
  });

  // Query to fetch rewards
  const { data, loading, error, refetch } = useQuery(MY_REWARDS, {
    variables: { shopID: user ? user.id : '' },
    skip: !user,
  });

  const handleFabClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // This will trigger the file input
    } else {
      console.error("File input ref is null");
    }
  };
  // Mutation to add a reward
  const [insertReward, { loading: addingReward }] = useMutation(ADD_REWARD, {
    refetchQueries: (mutationResult) => {
      console.log("Refetching with shopID:", user?.id); // Debug the shopID
      if (!user?.id) {
        console.error("shopID (user.id) is invalid or missing.");
        return [];
      }
      return [{ query: MY_REWARDS, variables: { shopID: user.id } }];
    },
    onCompleted: () => {
      setOpenDrawer(false);
      setNewReward({ Title: '', beans: '', imageurl: '', description: '' });
    },
    onError: (error) => {
      console.error("Error adding reward:", error.message);
    },
  });
  
//Delete Reward
 // Mutation to delete a reward
 const [deleteReward] = useMutation(DELETE_ONE_REWARD, {
    onCompleted: () => {
      console.log('Reward deleted successfully');
      refetch(); // Refetch rewards to update the list
    },
    onError: (err) => {
      console.error('Error deleting reward:', err.message);
    },
  });

  // Handle form submission
  const handleAddReward = async () => {
    let uploadedImageURL = null;

    if (file) {
      uploadedImageURL = await uploadFile(file, user.id);
      
      if (!uploadedImageURL) {
        console.error('File upload failed: no image URL returned');
        setIsUploading(false);
        return;
      }
    }

    insertReward({
      variables: {
        data: {
          Title: newReward.Title,
          beans: parseInt(newReward.beans),
          imageurl: uploadedImageURL,
          available: true,
          description: newReward.description,
          shopID: user.id, // Assuming shopID is the user's ID
        },
      },
    });
  };

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewReward((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Box sx={{ padding: 0, mt: 0 }}>
     

      {/* Drawer Component */}
      <Box sx={{ padding: 0, mt: 0 }}>
  {/* Drawer Component */}
  <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
    <Box sx={{ padding: 3 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">Add New Reward</Typography>
        <IconButton onClick={() => setOpenDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Image Upload and Preview */}
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        {previewURL ? (
          <Box position="relative" sx={{ display: 'inline-block' }}>
            <CardMedia
              component={isVideo ? "video" : "img"}
              controls={isVideo}
              alt="Preview"
              image={previewURL}
              sx={{ maxWidth: "100%", borderRadius: 2 }}
            />
            <IconButton
              onClick={handleRemoveFile}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "grey.900",
                backgroundColor: "common.white",
                "&:hover": { backgroundColor: "grey.100" },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
              border: "1px dashed grey",
              borderRadius: 2,
              cursor: "pointer",
            }}
            onClick={() => fileInputRef.current.click()}
          >
            <AddPhotoAlternateOutlinedIcon fontSize="large" sx={{ color: "grey.600", mb: 1 }} />
            <Typography variant="body2" color="text.secondary">
              Click to upload an image or video
            </Typography>
          </Box>
        )}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Box>

      {/* Reward Form */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Title"
            name="Title"
            value={newReward.Title}
            onChange={handleChange}
            fullWidth
            margin="dense"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Beans"
            name="beans"
            type="number"
            value={newReward.beans}
            onChange={handleChange}
            fullWidth
            margin="dense"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            name="description"
            value={newReward.description}
            onChange={handleChange}
            fullWidth
            margin="dense"
            multiline
            rows={3}
          />
        </Grid>
      </Grid>

      {/* Add Reward Button */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        {isUploading ? (
          <CircularProgress />
        ) : (
          <Chip
            label="Add Reward"
            onClick={handleAddReward}
            sx={{ backgroundColor: '#077336', color: 'white', padding: '0 16px', height: 40 }}
          />
        )}
      </Box>
    </Box>
  </Drawer>
</Box>


      {/* Display Rewards */}
      <Box sx={{ marginTop: 0 }}>
      {data?.rewards?.length > 0 && (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0 , mb: 1}}>
    <Typography variant="body1" gutterBottom fontWeight={500}>
      My Rewards
    </Typography>
    <Button
      variant="contained"
      color="success"
      onClick={() => setOpenDrawer(true)}
      sx={{ textTransform: 'none', fontWeight: 'bold' }}
    >
      Add Reward
    </Button>
  </Box>
)}

  
  {loading ? (
    <Typography>Loading...</Typography>
  ) : error ? (
    <Typography color="error">Error: {error.message}</Typography>
  ) : data?.rewards?.length === 0 ? ( // Check if rewards array is empty
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 2,
      padding: 2,
      textAlign: 'center',
      position: 'relative', // Enable positioning for the image
     
    }}
  >
    {/* DigiBean Character Image */}
    <Box
      sx={{
        position: 'absolute',
        top: 0, // Adjust the position as needed
        right: -20, // Adjust the position as needed
        width: 80, // Adjust the size as needed
        height: 80,
      }}
    >
      <img
        src="https://storage.googleapis.com/app_darkendimg/newton.png" // Replace with the actual image URL
        alt="DigiBean Character"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />
    </Box>
  
    {/* Message and Button */}
    <Typography variant="body1"  sx={{ fontWeight: 500, textAlign: 'left' }}>
      You have not added any drink rewards for redemption. Increase engagement with your customers by adding DigiBean redemption options.
    </Typography>
    <Button
      variant="contained"
      color="success"
      onClick={() => setOpenDrawer(true)} // Open the "Add Reward" drawer
      sx={{ textTransform: 'none', fontWeight: 'bold' }}
    >
      Add Reward
    </Button>
  </Box>
  
  ) : (

    
    <UsersList
    >
        
      {data?.rewards.map((reward) => (
        <Card
          key={reward._id}
          sx={{
            flex: '0 0 auto', // Prevent cards from shrinking
            width: 200, // Fixed card width
            boxShadow: 2,
            borderRadius: 2,
          }}
        >
                <CardMedia
    component="img"
    sx={{
      height: 120, 
      width: '100%', // Ensure the image spans the full width of the card
      objectFit: 'cover', // This ensures the image covers the entire area
    }}
    image={reward.imageurl || 'https://via.placeholder.com/300'}
    alt={reward.Title}
  />
          <CardContent sx={{ textAlign: 'center', pt: 3 }}> 
  <Typography variant="h5" sx={{ fontWeight: 'bold', fontFamily: 'Roboto Slab' }}> 
    {reward.Title}
  </Typography>
  <Typography variant="body1" sx={{ fontFamily: 'Roboto', mt: 1 }}>
    {reward.description}
  </Typography>
  <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Roboto', mt: 1 }}>
    Beans: {reward.beans}
  </Typography>
</CardContent>
<CardActions sx={{ justifyContent: 'center', padding: 2 }}>
  <Chip 
    label="Delete" 
    onClick={() => deleteReward({ variables: { query: { _id: reward._id } } })} 
    onDelete={() => deleteReward({ variables: { query: { _id: reward._id } } })}
    color="error" 
    variant="outlined" 
    sx={{ mr: 1 }} 
  />
  <Chip 
    label="Edit" 
    color="primary" 
    variant="outlined" 
    onClick={() => { /* Add your edit logic here */ }} 
  />
</CardActions>
        </Card>
      ))}
    </UsersList>
  )}
  
</Box>

    </Box>
  );
};

export default AddRewardComponent;
