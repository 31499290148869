import React, { useState } from 'react';
import {
  Container,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Modal,
  Chip,
  Icon,
  IconButton,
} from '@mui/material';
import useKupToken from '../../blockchain/useKupToken';
import { Web3ModalContext } from '../../constants/Web3ModalProvider';
import { useContext } from 'react';
import eth from '../../images/ethstar.svg';
import Koffieticket from './prods/coffee';
import CountryTicket from './prods/countryfeed';
import PriceTicket from './prods/pricefeed';
import { ArrowBack, ArrowBackIos, Close, Construction, ConstructionOutlined } from '@mui/icons-material';
import Wallet from '../../constants/wallet';
import { gql, useMutation } from '@apollo/client';
import { UserContext } from '../../context/usercontext';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';

const UPDATE_USER_PROF = gql`
  mutation UpdateUserprof($query: UserprofQueryInput!, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      tasteProfile
      description
      name
      country
      usertype
      beanCount
      imageurl
      profileBuilt
      waitlist
      
    }
  }
`;

const MarketplaceAdd = () => {
  const { user } = useContext(UserContext);
  const [updateUserprof] = useMutation(UPDATE_USER_PROF);
  const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
  
  const renderMedia = (url, title, backgroundColor) => {
    const isVideo = url.match(/\.(mp4)$/i);
    return isVideo ? (
      <video controls width="250" style={{  padding: 0 }}>
        <source src={url} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    ) : (
        <CardMedia
        component="img"
        image={url}
        alt={title}
        width="100%"
        sx={{ borderRadius: '10px 10px 0px 0px', backgroundColor: backgroundColor }}
        />
        );
     
  };

    const { account, connect } = useContext(Web3ModalContext);
  const { totalCherries } = useKupToken(account);

  const handleJoinWaitlist = async () => {
    try {
      await updateUserprof({
        variables: {
          query: { _id: user._id }, // Ensure the query is using the logged-in user's ID
          set: { waitlist: true }
        }
      });
      setOpen(true); // Open the modal on success
    } catch (error) {
      console.error("Error joining waitlist", error);
    }
  };
  // Function to close the modal
const handleClose = () => {
  setOpen(false);
  setOpenDialog(false);
};
// Mock data for NFT items
  const [nftItems, setNftItems] = useState([
    {
      id: 1,
      title: 'Farmer Bean',
      description: 'Famer Bean is the first of the Bean Heads NFT Collection.',
      image: 'https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png',
      price: .9,
      backgroundColor: '#3cb371',
      owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
    },
    {
      id: 2,
      title: 'The Why',
      description: 'Description for NFT Item 2',
      image: 'https://storage.googleapis.com/app_darkendimg/lilbean.png',
        price: 8,
        backgroundColor: '#9370db',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
    },
    {
        id: 1,
        title: 'Rizzo Bean',
        description: 'Rizzo Bean is a bean with Charisma. If you have charisma, this is the bean for you ',
        image: 'https://storage.googleapis.com/app_darkendimg/lilbeanie.png',
        price: 2.5,
        backgroundColor: '#ba55d3',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      {
        id: 1,
        title: 'Bean Runner',
        description: 'Runner Bean is the fastest bean in the Bean Heads Collection. If you are fast, this is your bean.',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/purp.png',
        price: 1,
        backgroundColor: '#dda0dd',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      {
        id: 1,
        title: 'Silver  Bean ',
        description: 'Silver Bean is all about economics. If you are into economics, this is your bean.',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/tinbean.png',
        price: 1.7,
        backgroundColor: '#e9d66b',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      {
        id: 1,
        title: 'Trapper Bean',
        description: 'Trapper Bean is from the streets. He is the only bean that can trap hard.',
        image: 'https://storage.googleapis.com/app_darkendimg/youngbean.png',
        price: 2,
        backgroundColor: '#431c53',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      {
        id: 1,
        title: 'Seargant Bean',
        description: 'Seargant Bean is the most disciplined bean in the Bean Heads Collection. He marches to the bean of his own cup.',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/trooper.png',
        price: 3 ,
        backgroundColor: '#2d8c39',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      {
        id: 1,
        title: 'Lil Bean',
        description: 'Lil is the a Rap Bean. He is the only bean that can spit sixteen bars.',
        image: 'https://storage.googleapis.com/app_darkendimg/iceicebeaine.png',
        price: 1,
        backgroundColor: '#f28e1c',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      {
        id: 1,
        title: 'Goldie Bean ',
        description: 'Goldie Bean is obsessed with Gold. If you are too, then this is your bean.',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/beezy.png',
        price: 1.7,
        backgroundColor: '#54626f',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      {
        id: 1,
        title: 'Bean Diesel',
        description: 'Bean Diesel is a Rebel bean. He is the only bean that can ride a bike.',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/bikerbean.png',
        price: 2,
        backgroundColor: '#7c0802',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      {
        id: 1,
        title: 'Posh Bean',
        description: 'Posh Bean is all about the finer things in life. If this is you, then this is your bean.',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/jetset.png',
        price: 3 ,
        backgroundColor: '#4997d0',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      {
        id: 1,
        title: 'Brainy Bean',
        description: 'Brainy Bean is what happens when you wake up to coffee. It just makes you smarter.',
        image: 'https://storage.googleapis.com/app_darkendimg/newton.png',
        price: 1,
        backgroundColor: '#6f4e37',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      {
        id: 1,
        title: 'Queen Bean ',
        description: 'Queen Bean is the queen of the Bean Heads Collection. Kinda like the Queen of batch.',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/royal.png',
        price: 1.7,
        backgroundColor: '#e7feff',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      {
        id: 1,
        title: 'Mad Bean',
        description: 'The Mad Bean is the most sought after Bean in the Bean Heads Collection. Mad Bean sees through the matrix.',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/madbean.png',
        price: 2,
        backgroundColor: '#4f86f7',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      {
        id: 1,
        title: 'Borer Bean',
        description: 'Borer Bean represents the borer beetle that has been a pest to the coffee industry. But she is cute',
        image: 'https://storage.googleapis.com/app_darkendimg/bennyborer.png',
        price: 3 ,
        backgroundColor: '#d6d6d6',
        owner: 'xdc8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01',
      },
      
    // Add more NFT items here
  ]);

  // State for dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // Function to handle click on NFT item
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  // Function to handle dialog close
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };

  return (
    <Container sx={{ mb: 1, mt: 0 }}>  
     

     
     <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingX: 0 }}>
        <Typography variant="h5" fontWeight={700} gutterBottom>
            Bean Heads NFT
        </Typography>
        </Box>

     <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', paddingBottom: '16px' }}>
  <Grid container spacing={2} style={{ flexWrap: 'nowrap', display: 'inline-flex', width: 'calc(100% - 50px)' }}>
    
    {renderMedia && nftItems.map((item) => (
      <Grid
        item
        xs={4}
        sm={6}
        md={4}
        key={item.id}
        style={{
          display: 'inline-block',
          marginRight: item === nftItems[nftItems.length - 1] ? '0' : '0px', // Add spacing except for the last item
        }}
      >
        <Card sx={{ borderRadius: '10px 10px 4px 4px', boxShadow: 8 }}>
          <CardActionArea onClick={() => handleItemClick(item)} sx={{ padding: 0 }}>
            {renderMedia(item.image, item.title, item.backgroundColor)}
            <CardContent sx={{ borderRadius: '0px 0px 10px 10px' ,paddingTop: 0 }}>
              <Typography variant="body1" fontWeight={700}>{item.title}</Typography>
              <Typography variant="body2" fontWeight={500}>{item.number}</Typography>
              <Typography variant="caption">
                Owner: {item.owner ? `${item.owner.substring(0, 21)}...` : "N/A"}
              </Typography>
              <Typography variant="body2">{totalCherries}</Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1" fontWeight={400}>
                  {item.price} ETH <img src={eth} alt="ETH" style={{ width: 20, height: 20 }} />
                </Typography>
                <Typography variant="h6">Buy</Typography>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    ))}
  </Grid>
</div>

      
      <Modal
  open={openDialog}
  onClose={handleCloseDialog}
  sx={{
    display: 'flex', // Ensures centering
    alignItems: 'center', // Vertically centers the modal
    justifyContent: 'center', // Horizontally centers the modal
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adds a semi-transparent background
    zIndex: 2500,
  }}
>
  {/* Modal Content */}
  <Box
    sx={{
      position: 'relative', // Enables absolute positioning for the close button
      width: '90%', // Responsive width
      maxWidth: 500, // Max width for desktop
      backgroundColor: selectedItem?.backgroundColor || '#ffffff', // Use custom or default background color
      borderRadius: 5,
      padding: 3,
      boxShadow: 24,
    }}
  >
    {/* Close Button */}
    <Box
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
      }}
    >
      <IconButton aria-label="close" onClick={handleCloseDialog}>
        <Close />
      </IconButton>
    </Box>

    {/* Title */}
    <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: 2 }}>
      {selectedItem && selectedItem.title}
    </Typography>

    {/* Content */}
    <Box>
      {/* Display the image of the NFT */}
      {selectedItem && renderMedia(selectedItem.image, selectedItem.title)}

      {/* Additional information about the NFT */}
      <Box mt={2}>
        <Typography variant="body2" sx={{ marginBottom: 1 }}>
          {selectedItem && selectedItem.description}
        </Typography>
        <Typography variant="body1">
          Owner: {selectedItem?.owner ? `${selectedItem.owner.substring(0, 18)}...` : "N/A"}
        </Typography>
        <Typography variant="body1">Price: {selectedItem?.price}</Typography>
      </Box>
    </Box>

    {/* Action Buttons */}
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
      <Button
        variant="contained"
        onClick={handleJoinWaitlist}
        sx={{ backgroundColor: '#077336', color: '#fff', borderRadius: 5 }}
      >
        Add to Wishlist
      </Button>
    </Box>
  </Box>
</Modal>



<Modal open={open} onClose={handleClose} sx={{ zIndex: 2500 }}>
        <Box
          sx={{
            position: 'fixed',
            width: 300,
            height: 200,
            backgroundColor: 'transparent',
            borderRadius: 4,
            boxShadow: 3,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            border: '0px solid green',
            margin: 'auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: isDarkMode ? '#121212' : '#fff',
            color: isDarkMode ? '#fff' : '#000',
          }}
        >
          <Typography variant="h5" fontWeight="bold" gutterBottom color={'green'}>
            Success!{' '}
            <img src="https://storage.googleapis.com/app_darkendimg/newton.png" alt="beanify" style={{ width: 30, height: 30 }} />
          </Typography>
          <Typography variant="body1" gutterBottom >
            You have successfully joined the waitlist. Stay Tuned.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{ marginTop: 2, backgroundColor: 'green', border: ' none', color: 'white', ':hover': { backgroundColor: 'darkgreen' } }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default MarketplaceAdd;
