import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import CommentIcon from '@mui/icons-material/Comment';
import StoreIcon from '@mui/icons-material/Store';
import { Link } from 'react-router-dom';
import Faq from '../../pages/faq';
import { Grid } from '@mui/material';


const beanRewards = [
  { number: 1, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png', likeCount: 1 },
  { number: 2, imgSrc: 'https://storage.googleapis.com/app_darkendimg/lilbean.png', likeCount: 5 },
  { number: 3, imgSrc: 'https://storage.googleapis.com/app_darkendimg/lilbeanie.png', likeCount: 10 },
  { number: 4, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/purp.png', likeCount: 20 },
  { number: 5, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/tinbean.png', likeCount: 100 },
  { number: 6, imgSrc: 'https://storage.googleapis.com/app_darkendimg/youngbean.png', likeCount: 200 },
  { number: 7, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/trooper.png', likeCount: 300 },
  { number: 8, imgSrc: 'https://storage.googleapis.com/app_darkendimg/iceicebeaine.png', likeCount: 400 },
  { number: 9, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/beezy.png', likeCount: 500 },
  { number: 10, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/bikerbean.png', likeCount: 1000 },
  { number: 11, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/jetset.png', likeCount: 2000 },
  { number: 12, imgSrc: 'https://storage.googleapis.com/app_darkendimg/newton.png', likeCount: 3000 },
  { number: 13, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/royal.png', likeCount: 4000 },
  { number: 14, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/madbean.png', likeCount: 5000 },
  { number: 15, imgSrc: 'https://storage.googleapis.com/app_darkendimg/bennyborer.png', likeCount: -1 },


  // Add more as needed
];

export default function BeanList() {
  return (

    
    <Box sx={{ width: '100%',  margin: '0 auto', mt: 1, padding: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: 700, textAlign: 'center' }}>
        Bean Head Rewards
      </Typography>
       <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 1, mb: 1 }}>
      {beanRewards.map((reward, index) => (
  <Grid 
    item 
    xs={2} // For very small screens, each icon takes 1/12th of the row (12 columns in total)
    sm={2} // For small screens, each icon takes 2/12th (6 columns per row)
    md={2} // For medium screens and above, each icon takes 2.4/12th (5 icons per row)
    key={index} 
    
    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
  >
    <Avatar sx={{ width: 70, height: 70 }} src={reward.imgSrc} />
    <Typography variant="caption" sx={{ fontWeight: 700, textAlign: 'center' }}>
      {reward.likeCount}
    </Typography>
  </Grid>
))}
      </Box>
      
      <List>
        <ListItem>
          <ListItemIcon>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText primary="5 beans per post" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <ProfileIcon />
          </ListItemIcon>
          <ListItemText primary="X Beans per like" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <CommentIcon />
          </ListItemIcon>
          <ListItemText primary="3 beans per comment" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary="50 beans for every item added to your AI salesman store" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary="200 beans for every item added to Smart market" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <Link to="/reviews" style={{ textDecoration: 'none', color: '#077336' }}>
            
          <ListItemText  primary="Go to Rewards to see Drink redemtion and terms" />
          </Link>
        </ListItem>
        <Divider />
      </List>
     
    </Box>
  );
}
