import React, { useContext } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  Box,
  Typography,
  CircularProgress,
  Avatar,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import { UserContext } from '../../../context/usercontext';

export const GET_DRINK_REDEEMS = gql`
  query GetDrinkRedeems($shop: ObjectId!) {
    redeems(query: { shop: $shop }) {
      _id
      userID
      shop
      redeemedAt
      Title
      uimage
      name
      imageurl
      reward
    }
  }
`;

export const GET_USER_REDEEMS = gql`
  query GetUserRedeems($userID: ObjectId!) {
    redeems(query: { userID: $userID }) {
      _id
      userID
      shop
      redeemedAt
      Title
      uimage
      name
      reward
      imageurl
    }
  }
`;



const DrinkRedeemList = () => {
  const { user } = useContext(UserContext);
  const {usertype} = user.customData;
  const isCoffeeShop = usertype === 'coffeeShop';

  // Dynamically select the query and variables
  const { loading, error, data } = useQuery(
    isCoffeeShop ? GET_DRINK_REDEEMS : GET_USER_REDEEMS,
    {
      variables: isCoffeeShop ? { shop: user.id } : { userID: user.id },
    }
  );

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  return (
    <Box sx={{ padding: 2 }}>
      {loading && <CircularProgress />}
      {error && <Typography color="error">Error: {error.message}</Typography>}

      {data && data.redeems && (
        <Box>
          {/* Header Section */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Total Redeemed Beans: {data.redeems.reduce((acc, curr) => acc + curr.reward, 0)}
            </Typography>
            {isCoffeeShop && (
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                Koffieland Payout: ${(data.redeems.length * 0.2).toFixed(2)}
              </Typography>
            )}
          </Box>

          {/* Grid Layout for Redeems */}
          {/* Grid Layout for Redeems */}
          <Grid container spacing={2}>
            {data.redeems.map((redeem) => (
              <Grid item xs={4} sm={6} md={4} key={redeem._id}>
                <Card sx={{ boxShadow: 3, borderRadius: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
                  {/* Reward Image */}
                  <CardMedia
                    component="img"
                    height="100"
                    image={redeem.imageurl || 'https://via.placeholder.com/300'} // Fallback image
                    alt={redeem.Title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 1 }}>
                      {/* User Avatar */}
                      <Avatar src={redeem.uimage} alt={redeem.name} />
                      {/* User Name */}
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {redeem.name}
                      </Typography>
                    </Box>
                    {/* Reward Title */}
                    <Typography variant="body2" color="text.secondary" sx={{  marginBottom: 1 }}>
                      {redeem.Title}
                    </Typography>
                    {/* Bean Count */}
                    <Typography variant="body2" sx={{ }}>
                      Beans: {redeem.reward}
                    </Typography>
                    {/* Redeem Date */}
                    <Typography variant="caption" color="text.secondary">
                      {formatDate(redeem.redeemedAt)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {data && data.redeems.length === 0 && (
        <Typography>No redeems found for your account.</Typography>
      )}

      {!data && !loading && (
        <Typography>
          You have not redeemed DigiBeans yet. Spread the word by letting your customers know.
        </Typography>
      )}
    </Box>
  );
};

export default DrinkRedeemList;
