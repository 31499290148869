import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { UserContext } from '../../context/usercontext';
import { useContext } from 'react';
import Wallet from '../../constants/wallet';
import { Link } from 'react-router-dom';
import Button from '../../components/portal/Button';
import kcoin from '../../images/kcoin.png';
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';
import { useBag } from '../../context/BagContext';
import PaymentIcon from '@mui/icons-material/Payment';
import { useNavigate } from 'react-router-dom';
import { ShoppingBagOutlined } from '@mui/icons-material';
import { useSpring, animated } from 'react-spring';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import BeanTracker from '../mods/beantracker';



const useStyles = makeStyles((theme) => ({
  menuBox: {
  
    padding: 0,
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'space-around',
    marginTop: 20,
    paddingBottom: 5,
    
   
    '&:hover': {
      backgroundColor: 'none',
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  menuItem: {
    fontSize: 16,
    fontWeight: 500,
    '&:hover': {
      color: '#077336',
    },
  },
  logoContainer: {
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(2),
  },
  walletContainer: {
    paddingRight: theme.spacing(5),
  },
}));



export default function DeskMenu() {
  const { user } = useContext(UserContext);
  console.log(user);
  const { itemCount } = useBag();
  const { customData } = user || {};
  const {
    Nickname = "Guest", // Default fallback
    beanCount,
    imageurl ,
    _id,
    usertype, // Check for case sensitivity
  } = customData || {};
  
  console.log(user.customData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const classes = useStyles();
  const { itemsInBag , setItemsInBag, } = useBag();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlelogoClick = () => {
    navigate('/bag');
  }

  const handlepaid = () => {
    navigate('/payment-success');
  }

   //Spring style

   const styles = useSpring({
    from: { scale: 1 },
    to: async (next) => {
      while (true) {
        await next({ scale: 1.2 });
        await next({ scale: 1 });
      }
    },
    config: { duration: 2000, easing: t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t },
  });

  const formatDigiBeans = (count) => {
    if (count >= 1000) {
      return `${count / 1000}k`;
    }
    return count;
  };

  //fileter cart items that are Koffie and output the number of items
  const koffieItems = itemsInBag.filter((item) => item.category === 'Koffie');

  return (

    
   
   <>
    {/* Column 1: Logo */}
   

    {/* Column 2: Menu Items */}


    
   
      <Box display="flex" justifyContent="space-around" className={classes.menuBox}>
     
        <BeanTracker />
       
        <Box sx={{ border: 'solid 0px ', borderRadius: 1, padding: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
        
        {itemCount > 0 && (
              <>
                <IconButton component={Link} to="/bag" sx={{ position: 'relative' }}>
                  <Badge
                    badgeContent={itemCount}
                    overlap="circular"
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: '#ffc107',
                        color: '#000',
                        top: 0,
                        right: 0,
                        fontSize: '0.65rem',
                      },
                    }}
                  >
                    <ShoppingCartIcon sx={{ color: '#077336' }} />
                  </Badge>
                </IconButton>
                <Box component={Link} to="/bag" sx={{ position: 'relative', display: 'flex', alignItems: 'center',  }}>
  <animated.div style={styles}>
    <img
      src="https://storage.googleapis.com/app_darkendimg/newton.png"
      alt="Kcoin"
      style={{ width: 40, marginRight: 1, marginBottom: 10 }}
    />
  </animated.div>
  
  {/* Overlay count on top of the image in the upper left */}
  <Typography
    variant="caption"
    fontWeight={500}
    sx={{
      position: 'absolute', 
      top: 0,
      right: -5,
      color: '#ffc107',
      backgroundColor: 'transparent',
      padding: '2px 4px',
      borderRadius: '4px'
    }}
  >
    {formatDigiBeans(koffieItems.length * 4000)}
  </Typography>

</Box>


              </>
            )}

<>
<Avatar 
  alt={Nickname} 
  src={imageurl} 
  sx={{ width: 30, height: 30, mb: 5 }} 
  onClick={handleClick} 
  component={Link} 
  to={usertype ? `/${usertype}/${_id}` : "#"}
/>

</>
</Box>
   
      </Box>
    
      
    {/* Column 3: Wallet and Icon */}
   
     
    
    </>
    

   
  );
}