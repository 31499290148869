import React from 'react'
import Initcon from '../../blockchain/initcon'
import FarmerSupplySubmit from '../../blockchain/farmersupply'
import Process from '../../blockchain/process'
import RoasterSteps from '../../blockchain/roaster'
import { Typography, Box, Card, CardActions, CardContent, Chip, CardMedia, Divider, Container } from '@mui/material'
import ConnectButton from '../../blockchain/wbutton'
import coffeebeans from '../../images/coffeebeans.png'
import thelogo from '../../images/gklogo.png'

import CircleIcon from '@mui/icons-material/Circle';
import { useContext } from 'react'
import Wallet from '../../constants/wallet'
//import KoffieCalc from '../finance/koffiecalc'
import KoffieVisualizer from '../mods/valuechain/chaingang'
import VisualGrid from '../mods/valuechain/chaingang2'
//import ProdMan from '../../pages/prodman'
import kcoin from '../../images/kcoin.png'
import useKupToken from '../../blockchain/useKupToken'
import { Grid } from '@mui/material'
import { Web3ModalContext } from '../../constants/Web3ModalProvider'
import { Paper, List, ListItem, ListItemText, Dialog } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CardHeader } from '@mui/material';
import CoinFlip from '../mods/kcoin/coinflip';
import { useNavigate , Link} from 'react-router-dom'


const useStyles = makeStyles({
  card: {
    
    borderRadius: '12px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.4)',
    overflow: 'hidden',
    width: '350px',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
  cardHeader: {
    
    color: 'white',
    padding: '20px',
    fontSize: '1.2em',
    fontWeight: 'bold',
    borderRadius: '12px 12px 0 0',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.4)',
  },
  cardBody: {
    padding: '20px',
  },
  chip: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '6px 12px',
    borderRadius: '16px',
    marginBottom: '15px',
    fontSize: '0.9em',
  },
  chipIcon: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#4caf50',
    marginRight: '8px',
  },
  walletSection: {
   
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '15px',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  actionButton: {
    padding: '10px 15px',
    border: 'none',
    borderRadius: '20px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out',
  },
  setupWallet: {
    backgroundColor: '#ffc107',
    color: '#000',
    '&:hover': {
      backgroundColor: '#ffca28',
    },
  },
  goToBlockscan: {
    backgroundColor: '#077336',
    color: 'white',
    width: '100%',
    '&:hover': {
      background: '#077336',
    },
  },
  metamaskIcon: {
    width: '40px',
    height: '40px',
    marginRight: '10px',
    cursor: 'pointer',
  },
});


function ContractDetails() {
  const { account, connect } = useContext(Web3ModalContext);
  const { farmerSupplySubmit, totalCherries, budding, farmerProcess, processStep, fertilizing, drying, balance, farmerSupply } = useKupToken(account);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const totalBeans = totalCherries * 2;
  const handleClickOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }

  const goToFaqs = () => {
    navigate('/faq');
  }

  return (
    <Container >
    <Box  sx={{ display: 'flex',flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, padding: 0 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', mt: 1 ,mb: 0, }}>
  <IconButton
    sx={{
      position: 'absolute',
      left: 0,
      color: 'text.secondary',
      fontSize: '1rem',
    }}
    onClick={() => window.history.back()}
  >
    <CloseIcon />
  </IconButton>
  <Typography
    variant="body1"
    sx={{
      fontWeight: 700,
      textAlign: 'center',
      color: 'text.secondary',
      width: '100%', // Ensure the text takes the full width
    }}
  >
    Blockchain Tokens
  </Typography>
  <Button onClick={goToFaqs} sx={{ borderRadius: 5, backgroundColor: '#3ff107', color: '#000', border: 'none', ":hover": { backgroundColor: '#ffc107' }}}
                >
                  FAQ
                </Button>
</Box>
<Card
      sx={{
        borderRadius: 4,
        boxShadow: 3,
        width: '100%',
        margin: 'auto',
        overflow: 'hidden',
      }}
    >
      <CardContent sx={{ padding: 3 }}>
        {/* Wallet and Live Testnet Contract Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            Wallet
          </Typography>
          <Chip
            label="Live Testnet Contract"
            sx={{
             
              
              fontWeight: 600,
            }}
            icon={
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: 'green', // Green dot for online status
                  marginLeft: 1,
                }}
              />
            }
          />
        </Box>

        {/* Setup MetaMask Wallet Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
            mb: 3,
          }}
        >
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Setup MetaMask Wallet
          </Typography>
          <Chip
            label="MetaMask"
            sx={{
              
              
              fontWeight: 600,
              padding: 1,
            }}
            icon={
              <img
                src="https://storage.googleapis.com/app_darkendimg/assets/meta-mask.png"
                alt="MetaMask"
                style={{ width: 20, height: 20, marginLeft: 8 }}
              />
            }
            onClick={handleClickOpen}
          />
        </Box>

        {/* Already Setup Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Already setup?
          </Typography>
          <Chip
            label="Connect Wallet"
            sx={{
              backgroundColor: 'primary.light',
              color: 'primary.dark',
              fontWeight: 600,
              padding: 1,
            }}
            icon={
              <img
                src="https://storage.googleapis.com/app_darkendimg/assets/meta-mask.png"
                alt="MetaMask"
                style={{ width: 20, height: 20, marginLeft: 8 }}
              />
            }
            onClick={connect}
          />
        </Box>

        {/* View on Blockchain Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
           <CoinFlip/>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Link to="https://explorer.apothem.network/address/0x8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01#readContract">
            <Button
              
             
              sx={{
                color: 'white',
                backgroundColor: '#077336',
                padding: '8px 16px',
                borderRadius: 2,
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: 'secondary.dark',
                },
              }}
            >
              View on Blockchain
            </Button>
          </Link>
        </Box>
        </Box>
      </CardContent>
    </Card>


    <Dialog fullScreen open={open} onClose={handleClose}>
      <Box sx={{ display: 'flex',  padding: 2 }}>
      <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
        <CloseIcon />
      </IconButton>
      <Typography variant="h5" gutterBottom fontWeight={700}sx={{color: '#077336', }}>
          Install MetaMask
        </Typography>
        </Box>
   
      <Box 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
      }}
    >
      <Box
        
        sx={{ 
          padding: 1, 
          borderRadius: 2, 
          boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)', 
        }}
      >
        
       
      <a
        href="https://play.google.com/store/apps/details?id=io.metamask"
        target="_blank"
        rel="noopener noreferrer"
        style={{ margin: '3px', display: 'inline-block' }}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
          alt="Get it on Google Play"
          style={{ height: '50px' }}
        />
      </a>
      <a
        href="https://apps.apple.com/app/metamask/id1438144202"
        target="_blank"
        rel="noopener noreferrer"
        style={{ margin: '3px', display: 'inline-block' }}
      >
        <img
          src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
          alt="Download on the App Store"
          style={{ height: '50px' }}
        />
      </a>
    
        <Typography variant="h6" gutterBottom fontWeight={900}sx={{color: '#000', }}>
          XDC Testnet settings
        </Typography>
        <Typography variant="body2" gutterBottom fontWeight={400}sx={{color: '#000', }}>
          Connect to the XDC Testnet to interact with the KoffieApp smart contracts.
        </Typography>
        <List sx={{ padding: 0, color: '#000' }}>
          <ListItem>
            <ListItemText primary="Chain ID" secondary="51"  />
          </ListItem>
          <ListItem>
            <ListItemText primary="RPC Endpoint" secondary="https://rpc.apothem.network" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Websocket Endpoint" secondary="wss://ws.apothem.network" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Consensus" secondary="XDPoS" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Block Finality" secondary=">75%" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Consensus Nodes" secondary="up to 108 (masternodes)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Genesis Block Date" secondary="2019-05-31" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Transaction Fee" secondary="gas price 0.25 Gwei" />
          </ListItem>
        </List>
      </Box>
    </Box>
    
    </Dialog>
    
    <Grid container spacing={2} alignItems="center" sx={{  borderRadius: 2, padding: 2 }}>
  <Grid item sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
    <Typography variant="body1" component="div" sx={{ fontWeight: 700, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)' }}>
      Minted Tokens
    </Typography>

    {/* Farmer Supply Elevation */}
    {farmerSupply && (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img src='https://storage.googleapis.com/app_darkendimg/assets/treeprod.png' alt="Cherries" width={15} />
            <Typography variant="body2" sx={{ fontWeight: 400 }}>
              <b>Production</b>
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ fontWeight: 400 }}>
            {farmerSupply.elevation} lbs
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} /> {/* Divider between items */}
      </>
    )}

    {/* Total Cherries */}
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/acherry.png' alt="Cherries" width={15} />
        <Typography variant="body2" sx={{ fontWeight: 400 }}>
          <b>Cherries</b>
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ fontWeight: 400 }}>
        {totalCherries}
      </Typography>
    </Box>
    <Divider sx={{ my: 1 }} /> {/* Divider between items */}

    {/* Total Beans */}
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/kbean.png' alt="Beans" width={15} />
        <Typography variant="body2" sx={{ fontWeight: 400 }}>
          <b>Beans</b>
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ fontWeight: 400 }}>
        {totalBeans}
      </Typography>
    </Box>
  </Grid>
</Grid>
  


     
   
    <div>
        <Typography variant="h6" component="div" sx={{ marginBottom: 2, fontWeight: 700, paddingTop: 2 ,
        textShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)'
        }}>
    Admin Activates Contract 
    </Typography>
   
    <Initcon/>
   <Typography variant="h6" component="div" sx={{ marginBottom: 2, fontWeight: 700, paddingTop: 2,
  textShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)'
  }}>
    Farmer Supplies Coffee
    </Typography>
    <FarmerSupplySubmit/>

    <Typography variant="h5" component="div" sx={{ marginBottom: 2, fontWeight: 500, 
    textShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)'
    }}>
    Process Status
    </Typography>
    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1, }}>
    <Typography variant="body2" component="div" sx={{ marginBottom: 2,  padding: 1 }}> Connect with your coffee's journey. See each step, from seed to cup, with updates directly from the farmer who grew it. </Typography>
    
    </Box>
    <Process/>
    <Typography variant="h4" component="div" sx={{ marginBottom: 2, fontWeight: 500, 
    textShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)'
    }}>
    Roaster Steps
    </Typography>
    <RoasterSteps/>
    </div>
    <Card sx={{  maxWidth: '100%', margin: 'auto', zIndex: 1000, borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
      <CardContent>
        <Typography variant="body2" color="textSecondary" align="center">
          Smart Contract Disclaimer: The KoffieApp smart contracts are deployed on the blockchain and are immutable. Please use at your own risk.
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
          © 2024 All Rights Reserved, KoffieApp
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
          For more information, visit our block explorer at{' '}
          <Link color="inherit" href="https://xdc.org">
            XDC
          </Link>
        </Typography>
      </CardContent>
    </Card>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '1rem', paddingTop: '1rem', marginLeft: '-10px', marginRight: '-10px'  }}>
        
    <Card sx={{ 
    display: 'flex', 
    width: 'calc(100% + 10px)', // Adjust the calculation based on actual body padding
   
    backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/path/to/destination/koffiecandy.jpeg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: 150,
    borderRadius: 0,
    boxShadow: 0,
    paddingTop: 0,
    margin: 0,
    overflow: 'hidden', // Ensure no scrollbars appear due to negative margins
  }}>
     
     <Typography gutterBottom variant="h6" component="div" sx={{
    fontWeight: 700,
    color: 'white',
    textAlign: 'left',
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 2,
    textShadow: '2px 2px 4px #000000',  
    }}>
      Own Your Coffee, Disrupt the Big Coffee: Mint Beans, Back Farmers, Build Open Trade
    </Typography>
    </Card>
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '1rem', paddingTop: '1rem', marginLeft: '-15px', marginRight: '-15px'  }}>
    <Card sx={{ display: 'flex',  }}>
    <CardContent>
        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left',  }}>
        
        <Typography gutterBottom variant="h5" component="div"sx={{fontWeight: 600, color: 'inherit'}}>
          Smart Koffie beta
        </Typography>
        
        </div>
        <Typography variant="h6" color="text.secondary" sx={{foontweight: 900}}>
          This is the Blockchain!
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ marginTop: 2, padding: 0 }}>
          While cryptocurrencies come and go, riding waves of hype, the real value lies in blockchain technology. It's a foundational shift, offering a decentralized ledger that powers innovations far beyond the volatile crypto market.
        </Typography>
        
      </CardContent>
    </Card>
    </Box>
    <Card sx={{ display: 'flex', height: 100,  boxShadow: 5,}}>
    <CardMedia
      component="img"
      sx={{ width: 100, height: 100,  }} // Adjust the width as needed
      image="https://storage.googleapis.com/app_darkendimg/4gen.jpg" // Replace with your image URL
      alt="Famer's Image"
    />
    <CardContent sx={{ flexGrow: 1 }}>
      <Typography variant="body1">
       OneCoffee's Contract
      </Typography>
      {/* Add more Typography or other components as needed */}
    </CardContent>
    <CardActions>
    <Link to="/Farmer/64210e930ec8d64fefb79de6" style={{ textDecoration: 'none' }}>
      <Chip
        label="View Profile"
        sx={{
          cursor: 'pointer', // Add pointer cursor to indicate it's clickable
          '&:hover': {
            backgroundColor: 'primary.light', // Optional: Add hover effect
          },
        }}
      />
    </Link>
    </CardActions>
  </Card>
    <Typography variant="body1" component="div" sx={{ marginBottom: 2, fontWeight: 700, paddingTop: 2   }}>
      We're bringing you Koffies disruptive function to buy out an entire value chain, from the farmer to the roaster, and beyond rethinking how we 
      interact with and return real value to to the entire lifecyle of currency.
    </Typography>
    <Typography variant="h6" component="div" sx={{ marginBottom: 2, fontWeight: 700, paddingTop: 2   }}>
      Round 1: Validate life cycle of coffee beans by sourcing koffie from existing roasters to raise funds for farmers engament and onboarding.
    </Typography>
  <Typography variant="body2" color="text.secondary" sx={{ marginTop: 2, padding: 0 }}>
      Currently, there are <strong>45.8 million cherries - x2 - 92 million beans (23k lbs)</strong> available within this active Proof of Concept contract. While this interface allows for viewing contract details, interaction capabilities, including the utilization of digital wallets, are under active development and coming soon. Stay tuned for updates as we enhance your ability to engage directly with the blockchain!
</Typography>
<Box sx={{ 
      p: 2, // Padding for spacing
      border: '1px solid lightgrey', // Adds a subtle border
      borderRadius: 2, // Slightly rounded corners
      mb: 3 // Margin bottom for spacing between boxes 
    }}>
      <Typography variant="h6" gutterBottom> 
        {/* Your Main Heading */}
        Connect the Coffee Community, Revolutionize the Supply Chain: Introducing Koffie Coins

      </Typography>
      <Typography variant="body1"> 
         {/* Your Subheading / Explanatory Text */}
         Track your beans across a transparent network of farmers, roasters, coffee shops, and baristas. Mint Koffie Coins, support fair trade directly, and experience coffee like never before. 
      </Typography>
    </Box>
    </Box>
    </Container>
  )
}

export default ContractDetails