import React, { useState } from 'react';
import axios from 'axios';

const useFileUpload = () => {
  const [file, setFile] = useState(null);
  const [previewURL, setPreviewURL] = useState('');
  const [uploadURL, setUploadURL] = useState('');
  const [error, setError] = useState('');
  const fileInputRef = React.createRef();

  
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/webp'];
      const validVideoTypes = ['video/mp4', 'video/quicktime'];
  
      if (validImageTypes.includes(selectedFile.type) || validVideoTypes.includes(selectedFile.type)) {
        setFile(selectedFile); // Update file state
  
        // Revoke any previously created object URLs to prevent memory leaks
        if (previewURL) {
          URL.revokeObjectURL(previewURL);
        }
  
        // Create and set a new object URL for the selected file
        const objectURL = URL.createObjectURL(selectedFile);
        setPreviewURL(objectURL);
        setError('');
      } else {
        setError('Only images and videos (MP4, MOV) are allowed.');
        setFile(null);
        if (previewURL) {
          URL.revokeObjectURL(previewURL); // Clean up any existing preview
        }
        setPreviewURL('');
      }
    }
  };
  
  const handleRemoveFile = () => {
    if (previewURL) {
      URL.revokeObjectURL(previewURL); // Revoke the object URL to prevent memory leaks
    }
  
    // Clear states
    setFile(null);
    setPreviewURL('');
    setUploadURL('');
    setError('');
  
    // Reset the input value to allow re-upload of the same file
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  

  const uploadFile = async (file, userId) => {
    if (!file) {
      console.error('No file selected for upload');
      console.log("User ID:", userId);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId);

    try {
      const response = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('File uploaded:', response.data);
      if (response.data && response.data.url) {
        setUploadURL(response.data.url);
        return response.data.url;
      } else {
        console.error('No URL returned from server');
      }
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };

  return { file, previewURL, uploadURL, handleFileChange, uploadFile, handleRemoveFile, error };
};

export default useFileUpload;
