import React, { useContext } from 'react';
import { useQuery, gql , useMutation, useLazyQuery} from '@apollo/client';
import { 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography, 
  Box, 
  CircularProgress, 
  Avatar,
  Dialog,
  Chip,
  IconButton
} from '@mui/material';
//import { GET_USER_REDEEMS } from '../../../context/gqllogics';
import { UserContext } from '../../../context/usercontext';
import { useBag } from '../../../context/BagContext';
import { Link } from 'react-router-dom';
import { RefreshOutlined } from '@mui/icons-material';

// Image URL for Redeem Image
const RedeemImage = 'https://storage.googleapis.com/app_darkendimg/lilbeanie.png';

export const GET_USER_REDEEMS = gql`
  query GetUserRedeems($shopId: ObjectId!) {
    shopredeems(query: { shopId: $shopId, isPaid: false }) {
      _id
      userId
      shopId
      beanCount
      isPaid
      timestamp
      name
      uimage
      payout
    }
  }
`;

export const GET_PAID_REDEEMS = gql`
  query GetUserRedeems($shopId: ObjectId!) {
    shopredeems(query: { shopId: $shopId, isPaid: true }) {
      _id
      userId
      shopId
      beanCount
      isPaid
      timestamp
      name
      uimage
      payout
    }
  }
`;

export const INSERT_CART_MUTATION = gql`
mutation InsertOneCart($data: CartInsertInput!) {
  insertOneCart(data: $data) {
    userID
    productId
    price
    category
    imageurl
    name  
    redeemIds
  }
}
`;

export const GET_USERBEAN_REDEEMS = gql`
  query GetDrinkRedeems($userId: ObjectId!) {
    shopredeems(query: { userId: $userId }) {
      _id
      userId
      shopId
      beanCount
      isPaid
      timestamp
      name
      uimage
      payout
    }
  }
`;

export const MARK_AS_PAID_MUTATION = gql`
  mutation MarkAsPaid($ids: [ObjectId!]!) {
  updateManyShopredeems(
    query: { _id_in: $ids }
    set: { isPaid: true }
  ) {
    modifiedCount
  }
}

`;

const ShopRedeemList = () => {
  const { user } = useContext(UserContext);
  const {usertype} = user.customData;
  const isCoffeeShop = usertype === 'coffeeShop';
  const { itemsInBag, setItemsInBag } = useBag();
  const [markAsPaid] = useMutation(MARK_AS_PAID_MUTATION);
  const [openDialog, setOpenDialog] = React.useState(false);

  //dialog handlers
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  


  const {loading, error, data, refetch} = useQuery(
    isCoffeeShop ? GET_USER_REDEEMS : GET_USERBEAN_REDEEMS ,
    {
      variables: isCoffeeShop ? { shopId: user.id } : { userId: user.id },
    }
  );
   
//get paid redeems
  const {loading:loadingPaid, error:errorPaid, data:dataPaid, refetch:refetchPaid} = useQuery(
    GET_PAID_REDEEMS,
    {
      variables: { shopId: user.id },
    }
  );

  const handleRefresh = async () => {
    try {
      await refetch(); // Manually trigger the refetch
    } catch (err) {
      console.error('Error during refetch:', err);
    }
  };
 

  const [insertOneCart] = useMutation(INSERT_CART_MUTATION);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  function timeAgo(date) {
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;
  
    if (secondsPast < 60) {
      return `${Math.round(secondsPast)}s`;
    }
    if (secondsPast < 3600) {
      return `${Math.round(secondsPast / 60)}m`;
    }
    if (secondsPast <= 86400) {
      return `${Math.round(secondsPast / 3600)}h`;
    }
    if (secondsPast > 86400) {
      const day = date.getDate();
      const month = date.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
      const year = date.getFullYear() === now.getFullYear() ? "" : ` ${date.getFullYear()}`;
      return `${day} ${month}${year}`;
    }
  }

  const redeemItems = data?.shopredeems ?? [];

  // Sums up all 'payout' fields as integers
  // Sums up all 'payout' fields as integers
const totalPayout = data && data.shopredeems
? data.shopredeems.reduce((acc, curr) => {
    // Each payout is stored as an integer in cents (e.g., 20)
    const payoutInCents = Number(curr.payout || 0);
    return acc + payoutInCents;
  }, 0)
: 0;

console.log('Total Payout in cents:', totalPayout); 
// If you had 8 fields each with 20, this should log 160


const handleAddToBag = async () => {
  const newItem = {
    name: 'Koffieland Payout',
    price: totalPayout , // Convert to dollars
    shopID: user.id, // Use the shop ID here
    category: 'Payout',
    imageurl: RedeemImage,
   
    
  };

  // Update bag context
  setItemsInBag([...itemsInBag, newItem]);

  // Insert into cart via mutation
  try {
    await insertOneCart({
      variables: {
        data: {
          userID: user.id,
          price: totalPayout, // Convert to dollars
          category: 'Payout',
          name: newItem.name,
          isPaid: false,
          imageurl: RedeemImage,
          redeemIds: redeemItems.map((item) => item._id), // array of _id's
          quantity: 1,
        },
      },
    });
    console.log('Payout added to cart successfully');
  } catch (error) {
    console.error('Error adding payout to cart:', error);
  }
};

 const handleMarkAsPaid = async () => {
  if (!redeemItems.length) return; // or show some message

  const redeemIds = redeemItems.map((item) => item._id);

  try {
    const response = await markAsPaid({ variables: { ids: redeemIds } });
    console.log('modifiedCount:', response.data.updateManyShopredeems.modifiedCount);
  } catch (error) {
    console.error('Error marking as paid:', error);
  }
};


  return (
    <Box sx={{ padding: 0 }}>
     
           
      {loading && <CircularProgress />}
      {error && <Typography color="error">Error: {error.message}</Typography>}

      {data && data.shopredeems && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          
         {isCoffeeShop && (
          <Box
          sx={{
            display: 'flex',
            width: '100%', // Full width
            alignItems: 'center', // Align items vertically
            justifyContent: 'space-between', // Evenly space out items horizontally
            padding: '16px', // Add padding for breathing space
            backgroundColor: '#f9f9f9', // Light background for separation
            borderRadius: '8px', // Rounded corners for a sleek look
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            mb: 2, // Margin bottom for spacing
            mt: 1, // Margin top for spacing
        
          }}
        >
          {/* Total Payout */}
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              fontSize: '14px', // Slightly larger for better readability
              color: '#333', // Darker text color for better contrast
            }}
          >
            Total Payout: ${(totalPayout / 100).toFixed(2)}
          </Typography>
        
          {/* Refresh Button */}
          <IconButton
            onClick={handleRefresh}
            sx={{
              backgroundColor: '#e0f7fa', // Subtle blue background for visual cue
              '&:hover': { backgroundColor: '#b2ebf2' }, // Slightly darker hover effect
              color: '#00796b', // Matching the primary color scheme
              marginLeft: '8px', // Space between items
            }}
          >
            <RefreshOutlined />
          </IconButton>
        
          {/* Add to Bag Button */}
          <Button
            onClick={handleAddToBag}
            variant="contained"
            sx={{
              borderRadius: '20px', // Rounded for sleek look
              backgroundColor: '#077336', // Primary green
              textTransform: 'none', // Removes all caps
              fontSize: '14px', // Slightly larger for readability
              color: 'white', // White text for contrast
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
              padding: '8px 16px', // Proper padding for better clickability
              marginLeft: '8px', // Space between items
              '&:hover': { backgroundColor: '#065828' }, // Darker green on hover
              GET_USER_REDEEMS      }}
          >
            Add Payout to Bag
          </Button>
        </Box>
        )}



{!isCoffeeShop && (
          <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center', // Align items vertically
            justifyContent: 'space-between', // Evenly space out items horizontally
            padding: '16px', // Add padding for breathing space
            backgroundColor: '#f9f9f9', // Light background for separation
            borderRadius: '8px', // Rounded corners for a sleek look
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            mb: 2, // Margin bottom for spacing
            mt: 1, // Margin top for spacing
        
          }}
        >
          {/* Total Payout */}
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              fontSize: '14px', // Slightly larger for better readability
              color: '#333', // Darker text color for better contrast
            }}
          >
            Total Payout: ${(totalPayout / 100).toFixed(2)}
          </Typography>
        
          {/* Refresh Button */}
          <IconButton
            onClick={handleRefresh}
            sx={{
              backgroundColor: '#e0f7fa', // Subtle blue background for visual cue
              '&:hover': { backgroundColor: '#b2ebf2' }, // Slightly darker hover effect
              color: '#00796b', // Matching the primary color scheme
              marginLeft: '8px', // Space between items
            }}
          >
            <RefreshOutlined />
          </IconButton>
        
          {/* Add to Bag Button */}
          
        </Box>
        )}
        <Chip onClick={handleClickOpen} label="Paid DigiBeans" />
        <Dialog fullScreen open={openDialog} onClose={handleClose}>
          
          {loadingPaid && <CircularProgress />}
          {errorPaid && <Typography color="error">Error: {errorPaid.message}</Typography>}
          {dataPaid && dataPaid.shopredeems && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ p: 2 }}>
            
            <Button onClick={handleClose}>Close</Button>
            <Typography>Paid DigiBeans true</Typography>  
          </Box>
              <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                <Table>
                  <TableHead sx={{ backgroundColor: '#077336' }}>
                    <TableRow>
                      <TableCell sx={{ padding: '8px' }}>Client</TableCell>
                      <TableCell sx={{ padding: '8px' }}>Claimed</TableCell>
                      <TableCell sx={{ padding: '8px' }}>Cost</TableCell>
                      <TableCell sx={{ padding: '8px' }}>Redeemed</TableCell>
                      <TableCell sx={{ padding: '8px' }}>Shop ID</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataPaid.shopredeems.map((redeem) => (
                      <TableRow key={redeem._id}>
                        <TableCell sx={{ padding: '4px' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Avatar alt={redeem.name} src={redeem.uimage} />
                            {redeem.name}
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '4px' }}>{redeem.beanCount}</TableCell>
                        <TableCell sx={{ padding: '4px' }}>${(Number(redeem.payout) * 0.01).toFixed(2)}</TableCell>
                        <TableCell sx={{ padding: '4px' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {timeAgo(new Date(redeem.timestamp))}
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '4px' }}>{redeem.shopId}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Dialog>


<TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
  <Table>
    <TableHead sx={{ backgroundColor: '#077336' }}>
      <TableRow>
        <TableCell sx={{ padding: '8px' }}>Client</TableCell>
        <TableCell sx={{ padding: '8px' }}>Claimed</TableCell>
        <TableCell sx={{ padding: '8px' }}>Cost</TableCell>
        <TableCell sx={{ padding: '8px' }}>Redeemed</TableCell>
        <TableCell sx={{ padding: '8px' }}>Shop ID</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data.shopredeems.map((redeem) => (
        <TableRow key={redeem._id}>
          <TableCell sx={{ padding: '4px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Avatar alt={redeem.name} src={redeem.uimage} />
              {redeem.name}
            </Box>
          </TableCell>
          <TableCell sx={{ padding: '4px' }}>{redeem.beanCount}</TableCell>
          <TableCell sx={{ padding: '4px' }}>${(Number(redeem.payout) * 0.01).toFixed(2)}</TableCell>
          <TableCell sx={{ padding: '4px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {timeAgo(new Date(redeem.timestamp))}
            </Box>
          </TableCell>
          <TableCell sx={{ padding: '4px' }}>{redeem.shopId}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

        </Box>
      )}

      {data && data.shopredeems.length === 0 && (
        <Typography>No redeems found for your account.</Typography>
      )}

      {!data && !loading && (
        <Typography>
          You have not redeemed DigiBeans yet. Spread the word by letting your customers know.
        </Typography>
      )}
       <Link to="/claimbeans/60968a5ea164e1cd11203dbf" style={{ textDecoration: 'none' }}>
                <Chip
                    label='Bean Claim'
                    icon={<img alt="Kup" src='https://storage.googleapis.com/app_darkendimg/assets/madbean.png' width={25} />}
                    sx={{
                        backgroundColor: '#d1e2f4',
                        border: '1px solid #d1e3f4',
                        borderRadius: 5,
                        cursor: 'pointer',
                    }}> </Chip>
            </Link>
    </Box>
  );
};

export default ShopRedeemList;
